import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { initialStateShippingCitySlice } from '../../../interfaces/cities';
import { ShippingCity } from '../../../interfaces/shippingCity';

/// TODO: wait for the back.. meanwhile is any
export const showPopupContentShippingCity = (shippingCity: any | undefined, mode: 'edit' | 'create' | 'view') => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (shippingCity) {
        dispatch(setShippingCityPopupContent(shippingCity));
        dispatch(setDetailPopupModeShippingCity('edit'));
      }

      break;
    case 'create':
      dispatch(setShippingCityPopupContent(undefined));
      dispatch(setDetailPopupModeShippingCity('create'));
      break;
    case 'view':
      if (shippingCity) {
        dispatch(setShippingCityPopupContent(shippingCity));
        dispatch(setDetailPopupModeShippingCity('view'));
      }

      break;
    default:
      break;
  }

  return dispatch(setShowPopupContentShippingCity(true));
};

export const showPopupDeleteShippingCity = (shippingCity: any) => async (dispatch: AppDispatch) => {
  dispatch(setShippingCityPopupContent(shippingCity));
  return dispatch(setShowPopupDeleteShippingCity(true));
};

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState: initialStateShippingCitySlice = {
  reloadData: false,
  showPopupContent: false,
  detailPopupMode: 'edit',
  showPopupDeleteShippingCity: false,
  permissions,
};

const shippingCitySlice = createSlice({
  name: 'shippingCity',
  initialState,
  reducers: {
    setReloadDataShippingCity(state, action: PayloadAction<boolean>) {
      state.reloadData = action.payload;
    },
    setShowPopupContentShippingCity(state, action: PayloadAction<boolean>) {
      state.showPopupContent = action.payload;
    },
    setShippingCityPopupContent(state, action: PayloadAction<ShippingCity | undefined>) {
      state.shippingCityPopupContent = action.payload;
    },
    setShowPopupDeleteShippingCity(state, action: PayloadAction<boolean>) {
      state.showPopupDeleteShippingCity = action.payload;
    },
    setDetailPopupModeShippingCity(state, action:PayloadAction<'edit' | 'create' | 'view'>) {
      state.detailPopupMode = action.payload;
    },
    setPermissionsShippingCity(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
  },
});

export const {
  setDetailPopupModeShippingCity,
  setPermissionsShippingCity,
  setReloadDataShippingCity,
  setShippingCityPopupContent,
  setShowPopupContentShippingCity,
  setShowPopupDeleteShippingCity
} = shippingCitySlice.actions;

export default shippingCitySlice.reducer;
