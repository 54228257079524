import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '..';

export const searchText = (textSearch: string) => (dispatch: AppDispatch) => {
  dispatch(setSearchText(textSearch));
  return dispatch(setSearch(true));
};

export const resetSearch = () => (dispatch: AppDispatch) => {
  dispatch(setSearchText(''));
  return dispatch(setSearch(false));
};

const initialState = {
  searchText: '',
  search: false
};

const SearchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchText(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
    setSearch(state, action: PayloadAction<boolean>) {
      state.search = action.payload;
    }
  },
});

export const { setSearchText, setSearch } = SearchSlice.actions;

export default SearchSlice.reducer;
