import { Provider } from 'react-redux';
import { LoadScript } from '@react-google-maps/api';

import store from './store';
import Router from './router';

import 'react-day-picker/dist/style.css';
import 'react-loading-skeleton/dist/skeleton.css';

const API_KEY = process.env.REACT_APP_MAPS_KEY || '';

function App() {
  return (
    <Provider store={store}>
      <LoadScript googleMapsApiKey={API_KEY}>
        <Router/>
      </LoadScript>
    </Provider>
  );
}

export default App;
