import { lazy } from 'react';

import { settingsPage } from '../../interfaces/configPages';

const UsersEcoPage = lazy(() => import('.'));

const UsersEcoPageConfig: settingsPage = {
  routes: [
    {
      path: '/ecommerce/users',
      element: <UsersEcoPage />
    },
  ],
};

export default UsersEcoPageConfig;
