import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppDispatch } from '../..';

import { permissionOptions } from '../../../interfaces/auth';
import { RecomendationState } from '../../../interfaces/recomendation';

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

export const showPopupRecomendation = (recomendation: any | undefined, mode: ModalMode) => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (recomendation) {
        dispatch(setRecomendation(recomendation));
        dispatch(setPopupMode('edit'));
      }

      break;
    case 'create':
      dispatch(setRecomendation(undefined));
      dispatch(setPopupMode('create'));
      break;
    case 'view':
      if (recomendation) {
        dispatch(setRecomendation(recomendation));
        dispatch(setPopupMode('view'));
      }

      break;
    case 'delete':
      if (recomendation) {
        dispatch(setRecomendation(recomendation));
        dispatch(setPopupMode('delete'));
      }

      break;

    default:
      break;
  }

  return dispatch(setShowPopup(true));
};

const initialState: RecomendationState = {
  showPopup: false,
  popupMode: 'edit',
  permissions,
  recomendation: undefined,
  reloadData: false
};

type ModalMode = RecomendationState['popupMode']

const recomendationSlice = createSlice({
  name: 'recomendationSlice',
  initialState,
  reducers: {
    setShowPopup(state, action: PayloadAction<boolean>) {
      state.showPopup = action.payload;
    },
    setPopupMode(state, action:PayloadAction<ModalMode>) {
      state.popupMode = action.payload;
    },
    setRecomendation(state, action: PayloadAction<any | undefined>) {
      state.recomendation = action.payload;
    },
    setRealadRecomendationData(state, action: PayloadAction<boolean>) {
      state.reloadData = action.payload;
    },
    setRecomendationPermissions(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
  }
});

export const {
  setShowPopup,
  setPopupMode,
  setRecomendation,
  setRealadRecomendationData,
  setRecomendationPermissions
} = recomendationSlice.actions;

export default recomendationSlice.reducer;
