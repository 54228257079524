import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { initProduct } from '../../../helper/product';
import { permissionOptions } from '../../../interfaces/auth';
import { getAtributes, getIngredients, initialStateProductSlice, productFullData } from '../../../interfaces/product';

export const showPopupContentProduct = (product: productFullData | undefined, mode: 'edit' | 'create' | 'view') => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (product) {
        dispatch(setProductPopupContent(product));
        dispatch(setDetailPopupMode('edit'));
      }

      break;
    case 'create':
      dispatch(setProductPopupContent(initProduct));
      dispatch(setDetailPopupMode('create'));
      break;
    case 'view':
      if (product) {
        dispatch(setProductPopupContent(product));
        dispatch(setDetailPopupMode('view'));
      }

      break;
    default:
      break;
  }

  return dispatch(setShowPopupContentProduct(true));
};

const productPopupContent: productFullData = initProduct;

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState: initialStateProductSlice = {
  reloadProducts: false,
  showPopupContent: false,
  productPopupContent,
  detailPopupMode: 'edit',
  permissions,
  attributes: [],
  ingredients: []
};

const profileSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setReloadDataProduct(state, action: PayloadAction<boolean>) {
      state.reloadProducts = action.payload;
    },
    setShowPopupContentProduct(state, action: PayloadAction<boolean>) {
      state.showPopupContent = action.payload;
    },
    setProductPopupContent(state, action: PayloadAction<productFullData>) {
      state.productPopupContent = action.payload;
    },
    setDetailPopupMode(state, action:PayloadAction<'edit' | 'create' | 'view'>) {
      state.detailPopupMode = action.payload;
    },
    setAttributes(state, action:PayloadAction<getAtributes[]>) {
      state.attributes = action.payload;
    },
    setPermissionsProducts(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
    setIngredients(state, action:PayloadAction<getIngredients[]>) {
      state.ingredients = action.payload;
    },
  },
});

export const { setReloadDataProduct, setShowPopupContentProduct, setProductPopupContent, setDetailPopupMode, setIngredients, setAttributes, setPermissionsProducts } = profileSlice.actions;

export default profileSlice.reducer;
