import { lazy } from 'react';

import { settingsPage } from '../../interfaces/configPages';

const CalendarPage = lazy(() => import('.'));

const CalendarPageConfig: settingsPage = {
  routes: [
    {
      path: '/calendar',
      element: <CalendarPage />
    },
  ],
};

export default CalendarPageConfig;
