import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { couponsComplete, initialStateCouponsSlice } from '../../../interfaces/coupons';

export const showPopupContentCoupon = (coupon: couponsComplete | undefined, mode: 'edit' | 'create' | 'view') => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (coupon) {
        dispatch(setCouponPopupContent(coupon));
        dispatch(setDetailPopupMode('edit'));
      }

      break;
    case 'create':
      dispatch(setDetailPopupMode('create'));
      break;
    case 'view':
      if (coupon) {
        dispatch(setCouponPopupContent(coupon));
        dispatch(setDetailPopupMode('view'));
      }

      break;
    default:
      break;
  }

  return dispatch(setShowPopupContentCoupon(true));
};

export const showPopupDeleteCoupon = (coupon: couponsComplete) => async (dispatch: AppDispatch) => {
  dispatch(setCouponPopupContent(coupon));
  return dispatch(setShowPopupDeleteCoupons(true));
};

const couponPopupContent: couponsComplete = {
  authorize: '',
  claimCode: '',
  coupon_types_id: '',
  createdAt: '',
  descr: '',
  discount: 0,
  expireDate: '',
  id: '',
  status: 0,
  internal_coupons_id: '',
  maxUses: 0,
  modalities_id: '',
  prerequisites: {
    applyProducts: [],
    firstBuy: 0,
    maxPrice: 0,
    minPrice: 0,
    minItems: 0,
    payWithCard: 0
  },
  prerequisites_id: '',
  purchase_types_id: '',
  updatedAt: '',
  allowedUsers: [],
  usesByUser: 0,
  couponType: {
    id: '',
    key: '',
    name: ''
  },
  feature: {
    id: '',
    key: '',
    name: '',
    percent_discount: -1
  },
  feature_id: '',
  internalCoupon: {
    code: '',
    id: '',
    name: ''
  },
  modality: {
    id: '',
    key: '',
    name: ''
  },
  purchaseType: {
    id: '',
    key: '',
    name: ''
  },
  country: '1'
};

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState: initialStateCouponsSlice = {
  reloadCoupons: false,
  showPopupContent: false,
  showPopupDeleteCoupons: false,
  showCreateFormCoupons: false,
  couponPopupContent,
  detailPopupMode: 'edit',
  permissions,
  isShowInternalCodeForm: false,
};

const couponSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    setReloadData(state, action: PayloadAction<boolean>) {
      state.reloadCoupons = action.payload;
    },
    setShowPopupContentCoupon(state, action: PayloadAction<boolean>) {
      state.showPopupContent = action.payload;
    },
    setCouponPopupContent(state, action: PayloadAction<couponsComplete>) {
      state.couponPopupContent = action.payload;
    },
    setShowPopupDeleteCoupons(state, action: PayloadAction<boolean>) {
      state.showPopupDeleteCoupons = action.payload;
    },
    setPermissionsCoupons(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
    setDetailPopupMode(state, action:PayloadAction<'edit' | 'create' | 'view'>) {
      state.detailPopupMode = action.payload;
    },
    setShowInternalCodeForm(state, action:PayloadAction<boolean>) {
      state.isShowInternalCodeForm = action.payload;
    }
  },
});

export const { setReloadData, setShowPopupContentCoupon, setCouponPopupContent, setShowPopupDeleteCoupons, setPermissionsCoupons, setDetailPopupMode, setShowInternalCodeForm } = couponSlice.actions;

export default couponSlice.reducer;
