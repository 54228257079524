import { lazy } from 'react';

import { settingsPage } from '../../interfaces/configPages';

const RecomendationsPage = lazy(() => import('.'));

const RecomendatiosPageConfig: settingsPage = {
  routes: [
    {
      path: '/users/recommendations',
      element: <RecomendationsPage />
    },
  ],
};

export default RecomendatiosPageConfig;
