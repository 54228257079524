import { lazy } from 'react';
import { settingsPage } from '../../../interfaces/configPages';

const StorePage = lazy(() => import('.'));

const StorePageConfig: settingsPage = {
  routes: [
    {
      path: '/inventory/stores',
      element: <StorePage />
    },
  ],
};

export default StorePageConfig;
