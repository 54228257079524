import { lazy } from 'react';
import { settingsPage } from '../../../interfaces/configPages';

const InventoryPage = lazy(() => import('.'));

const InventoryPageConfig: settingsPage = {
  routes: [
    {
      path: '/inventory/product',
      element: <InventoryPage />
    },
  ],
};

export default InventoryPageConfig;
