import { combineReducers, configureStore } from '@reduxjs/toolkit';
import auth from '../auth/store';
import pixie from './pixie/';
import router from '../router/store';

const store = configureStore({
  reducer: combineReducers({
    pixie,
    auth,
    router
  }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
