import { sidebarConfigInterface } from '../interfaces/sideBar';
import { getPermissionsApi, permissionOptions } from '../interfaces/auth';
import { capitalize } from './capitalize';

export const returnRouterObject = (data: getPermissionsApi[]): sidebarConfigInterface[] => {
  // Declare parents
  const perfiles: sidebarConfigInterface = {
    name: 'Perfiles',
    url: '',
    active: false,
    children: []
  };

  const pedidos: sidebarConfigInterface = {
    name: 'Pedidos',
    url: '',
    active: false,
    children: []
  };

  const metricas: sidebarConfigInterface = {
    name: 'Métricas',
    url: '/metrics',
    active: false,
  };

  const inventario: sidebarConfigInterface = {
    name: 'Inventario',
    url: '',
    active: false,
    children: []
  };
  const cupones: sidebarConfigInterface = {
    name: 'Cupones',
    url: '/coupons',
    active: false,
  };
  const products: sidebarConfigInterface = {
    name: 'Productos',
    url: '',
    active: false,
    children: []
  };
  const subscriptions: sidebarConfigInterface = {
    name: 'Suscripciones',
    url: '',
    active: false,
    children: []
  };
  const calendar: sidebarConfigInterface = {
    name: 'Calendario',
    url: '/calendar',
    active: false,
  };

  const reports: sidebarConfigInterface = {
    name: 'Reportes',
    url: '/reports',
    active: false,
  };

  const users: sidebarConfigInterface = {
    name: 'Usuarios y donde comprar',
    url: '/ecommerce/users',
    active: false,
  };

  const testimonials: sidebarConfigInterface = {
    name: 'Testimonios',
    url: '/testimonials',
    active: false,
  };

  const contentManager: sidebarConfigInterface = {
    name: 'Administrador de contenido',
    url: '/contentmanager',
    active: false,
  };

  // All people can see metrics
  const routerObject: sidebarConfigInterface[] = [metricas];
  // Validate other modules
  data.forEach(object => {
    const { module } = object;
    if (object.read === 1) {
      if (module.route?.includes('inventory'))
        inventario.children?.push({ active: false, name: capitalize(module.title), url: module.route });
      if (module.route?.includes('users/'))
        perfiles.children?.push({ active: false, name: capitalize(module.title), url: module.route });
      if (module.route?.includes('/logistics'))
        pedidos.children?.push({ active: false, name: capitalize(module.title), url: module.route });
      if (module.route?.includes('coupons'))
        routerObject.push(cupones);
      if (module.route?.includes('products'))
        products.children?.push({ active: false, name: capitalize(module.title), url: module.route });
      if (module.route?.includes('/subscription'))
        subscriptions.children?.push({ active: false, name: capitalize(module.title), url: module.route });
      if (module.route?.includes('calendar'))
        routerObject.push(calendar);
      if (module.route?.includes('reports'))
        routerObject.push(reports);
      if (module.route?.includes('ecommerce/users'))
        routerObject.push(users);
      if (module.route?.includes('testimonials'))
        routerObject.push(testimonials);
      if (module.route?.includes('contentmanager'))
        routerObject.push(contentManager);
    }
  });

  if (perfiles.children?.length !== 0)
    routerObject.push(perfiles);
  if (pedidos.children?.length !== 0)
    routerObject.push(pedidos);
  if (subscriptions.children?.length !== 0)
    routerObject.push(subscriptions);
  if (inventario.children?.length !== 0)
    routerObject.push(inventario);
  if (products.children?.length !== 0)
    routerObject.push(products);

  // Order sidebar
  routerObject.sort(compareName);

  return routerObject;
};

// Function to organize and show in the sidebar ordened
const compareName = (a: sidebarConfigInterface, b: sidebarConfigInterface) => {
  try {
    if (a.name.toLowerCase() > b.name.toLowerCase())
      return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase())
      return -1;
  } catch (error) {
    console.log(error);
  }

  return 0;
};

export const helperSendPermissions = (permissions: permissionOptions, mode: 'upload' | 'create' | 'read' | 'delete') => {
  const hasPermission = permissions[mode];
  const options = ['passieren', 'kulkea', 'descartvel'];
  const optionsFake = ['padjsdsa', 'nhjuiss', 'dsafda'];

  if (hasPermission === 0) {
    const randomOptionFake = optionsFake[Math.floor(Math.random() * optionsFake.length)];
    return randomOptionFake;
  }

  const randomOption = options[Math.floor(Math.random() * options.length)];
  return randomOption;
};
