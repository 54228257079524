import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { permissionOptions } from '../../../interfaces/auth';
import { PriceList, PriceListSliceData } from '../../../interfaces/cellars';
import { AppDispatch } from '../..';

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

export const showPopupContentPriceList = (priceList: PriceList | undefined, mode: ModalMode) => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (priceList) {
        dispatch(setProceList(priceList));
        dispatch(setPopupMode('edit'));
      }

      break;
    case 'create':
      dispatch(setProceList(undefined));
      dispatch(setPopupMode('create'));
      break;
    case 'view':
      if (priceList) {
        dispatch(setProceList(priceList));
        dispatch(setPopupMode('view'));
      }

      break;
    case 'delete':
      if (priceList) {
        dispatch(setProceList(priceList));
        dispatch(setPopupMode('delete'));
      }

      break;

    default:
      break;
  }

  return dispatch(setShowPopup(true));
};

const initialState: PriceListSliceData = {
  showPopup: false,
  popupMode: 'edit',
  permissions,
  priceList: undefined,
  reloadData: false
};

type ModalMode = PriceListSliceData['popupMode']

const priceListSlice = createSlice({
  name: 'priceListSlice',
  initialState,
  reducers: {
    setPermissionsPriceList(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
    setShowPopup(state, action: PayloadAction<boolean>) {
      state.showPopup = action.payload;
    },
    setPopupMode(state, action:PayloadAction<ModalMode>) {
      state.popupMode = action.payload;
    },
    setProceList(state, action: PayloadAction<PriceList | undefined>) {
      state.priceList = action.payload;
    },
    setCellarsPermissions(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
    setReloadPriceListData(state, action: PayloadAction<boolean>) {
      state.reloadData = action.payload;
    }
  }
});

export const {
  setCellarsPermissions,
  setReloadPriceListData,
  setProceList,
  setPermissionsPriceList,
  setPopupMode,
  setShowPopup,
} = priceListSlice.actions;

export default priceListSlice.reducer;

