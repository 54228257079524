import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../hooks';
import { changeSidebarSelected } from '../store/pixie/navigationSlice';

import authService from '../services/authService';
import { setHiddenNavigation, updateRouteAndTitle } from '../store/pixie/navigationSlice';
import { setReloadData } from './store/userSlice';

// Check if the user is connect
const Auth = ({ children }: AuthInterface) => {
  const session = useAppSelector(state => state.auth.login.success);
  const isLoadLoad = useAppSelector(state => state.auth.login.isLoadLoad);
  const configRoutesByRole = useAppSelector(state => state.router.router.configRouter);
  /// const [waitAuthCheck, setwaitAuthCheck] = useState(true);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const jwtCheck = async () => {
    const result = await authService.checkSession();
    if (result) {
      dispatch(updateRouteAndTitle(location.pathname, configRoutesByRole));
      dispatch(changeSidebarSelected(location.pathname, configRoutesByRole));
    } else {
      dispatch(setHiddenNavigation(true));
      navigate('/auth/login');
    }

    /// setwaitAuthCheck(false);
  };

  // Stay attention when the location change
  useEffect(() => {
    /// if (location.pathname.includes('auth'))
    //   setwaitAuthCheck(false);
    if (isLoadLoad)
      jwtCheck();
  }, [location, session, isLoadLoad]);

  // Put data in the aplication when entry in the fisrt time
  useEffect(() => {
    if (window.localStorage.getItem('id_user')) {
      const idUser = window.localStorage.getItem('id_user');
      dispatch(setReloadData(idUser ? idUser : ''));
    }
  }, []);

  return <>{children}</>;
};

export default Auth;
interface AuthInterface {
  children: JSX.Element;
}
