import { lazy } from 'react';

import { settingsPage } from '../../interfaces/configPages';

const ContentManagerPage = lazy(() => import('.'));

const ContentManagerPageConfig: settingsPage = {
  routes: [
    {
      path: '/contentmanager',
      element: <ContentManagerPage />
    },
  ],
};

export default ContentManagerPageConfig;
