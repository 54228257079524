import { lazy } from 'react';
import { settingsPage } from '../../interfaces/configPages';

const LoginPage = lazy(() => import('./login'));
const RecoveryPage = lazy(() => import('./recoveryPassword'));
const ResetPage = lazy(() => import('./resetPassword'));

const AuthPageConfig: settingsPage = {
  routes: [
    {
      path: 'auth/login',
      element: <LoginPage />
    },
    {
      path: 'auth/recovery',
      element: <RecoveryPage />
    },
    {
      path: 'auth/reset-password',
      element: <ResetPage />
    },
  ],
};

export default AuthPageConfig;
