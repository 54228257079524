import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { getSubscription, initialStateSubscriptionSlice } from '../../../interfaces/subscriptions';

export const showPopupContentSubscription = (subscription: getSubscription | undefined, mode: 'edit' | 'create' | 'view') => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (subscription) {
        dispatch(setSubscriptionPopupContent(subscription));
        dispatch(setDetailPopupMode('edit'));
      }

      break;
    case 'create':
      dispatch(setSubscriptionPopupContent(subscriptionPopupContent));
      dispatch(setDetailPopupMode('create'));
      break;
    case 'view':
      if (subscription) {
        dispatch(setSubscriptionPopupContent(subscription));
        dispatch(setDetailPopupMode('view'));
      }

      break;
    default:
      break;
  }

  return dispatch(setShowPopupContentSubscription(true));
};

export const showPopupDeleteCoupon = (subscription: getSubscription) => async (dispatch: AppDispatch) => {
  dispatch(setSubscriptionPopupContent(subscription));
  return dispatch(setShowPopupDeleteSubscription(true));
};

const subscriptionPopupContent: getSubscription = {
  days_subscriptions: 0,
  free_delivery: 0,
  free_delivery_us: 0,
  id: '',
  monts: 0,
  percen_discount: 0,
  status: 1,
  title: '',
  title_en: '',
  created_at: '',
  updated_at: '',
  start_date: ''
};

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState: initialStateSubscriptionSlice = {
  reloadSubscription: false,
  showPopupContent: false,
  showPopupDeleteSubscription: false,
  showCreateFormSubscription: false,
  subscriptionPopupContent,
  detailPopupMode: 'edit',
  permissions,
};

const SubscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setReloadData(state, action: PayloadAction<boolean>) {
      state.reloadSubscription = action.payload;
    },
    setShowPopupContentSubscription(state, action: PayloadAction<boolean>) {
      state.showPopupContent = action.payload;
    },
    setSubscriptionPopupContent(state, action: PayloadAction<getSubscription>) {
      state.subscriptionPopupContent = action.payload;
    },
    setShowPopupDeleteSubscription(state, action: PayloadAction<boolean>) {
      state.showPopupDeleteSubscription = action.payload;
    },
    setPermissionsSubscription(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
    setDetailPopupMode(state, action:PayloadAction<'edit' | 'create' | 'view'>) {
      state.detailPopupMode = action.payload;
    },
  },
});

export const { setDetailPopupMode, setPermissionsSubscription, setReloadData, setShowPopupContentSubscription, setShowPopupDeleteSubscription, setSubscriptionPopupContent } = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;
