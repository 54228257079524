import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { permissionOptions } from '../../../interfaces/auth';
import { initialStateContentManager } from '../../../interfaces/contentManager';

const permissions : permissionOptions = {
  read: 0,
  create: 0,
  upload: 0,
  delete: 0,
};

const initialState: initialStateContentManager = {
  country: '1',
  tab: 1,
  permissions
};

const contentManagerSlice = createSlice({
  name: 'contentManager',
  initialState,
  reducers: {
    setCountry(state, action: PayloadAction<string>) {
      state.country = action.payload;
    },
    setTab(state, action: PayloadAction<number>) {
      state.tab = action.payload;
    },
    setPermissionsContentManager(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    }
  }
});

export const { setCountry, setTab, setPermissionsContentManager } = contentManagerSlice.actions;

export default contentManagerSlice.reducer;
