import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { permissionOptions } from '../../../interfaces/auth';

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState = {
  permissions,
};

const attributeSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setPermissionsReports(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    }
  },
});

export const { setPermissionsReports } = attributeSlice.actions;

export default attributeSlice.reducer;
