import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../store';

import { User } from '../../interfaces/user';
import { loginError, loginExit, loginSuccess } from './loginSlice';
import authService from '../../services/authService';
import profileService from '../../services/profileService';
import { setInitialRoutesAndPermissions } from '../../router/store/routerSlice';

export const setUserData = (user : User, idRol: string) => async (dispatch: AppDispatch) => {
  window.localStorage.setItem('id_user', user.id);
  const userTemp: User = JSON.parse(JSON.stringify(user));
  userTemp.rol_id = idRol;
  dispatch(setUser(userTemp));
};

export const logOut = () => async (dispatch: AppDispatch) => {
  dispatch(userLoggedOut());
  authService.setSession();
  return dispatch(loginExit());
};

export const setReloadData = (idUser: string) => async (dispatch: AppDispatch) =>
  profileService
    .getUser(idUser)
    .then(user => {
      dispatch(setUserData(user, user.rol_id));
      dispatch(setLoadPermissions(user.rol_id));
      return dispatch(loginSuccess());
    })
    .catch(errors => dispatch(loginError(errors)));

export const setLoadPermissions = (idRol: string) => async (dispatch: AppDispatch) =>
  authService
    .getPermissionsByRole(idRol)
    .then(res => {
      dispatch(setInitialRoutesAndPermissions(res));
      dispatch(loginSuccess());
    })
    .catch(err => dispatch(loginError(err)));

const currentUser: User = {
  name: '',
  rol_id: '',
  email: '',
  phone: '',
  id: '',
  status: 0
};

const initialState = {
  currentUser
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.currentUser = action.payload;
    },
    userLoggedOut: () => initialState,
  },
  extraReducers: {},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
