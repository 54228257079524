import { useAppSelector } from '../../../hooks';
import Navbar from '../navbar';
import Sidebar from '../sidebar';

const ContainerPage = ({ children }: ContainerPageProps) => {
  const hiddenNavigation = useAppSelector(state => state.pixie.navigation.hidden);
  const minimize = useAppSelector(state => state.pixie.navigation.minimize);
  const session = useAppSelector(state => state.auth.login.success);
  const showScroll = useAppSelector(state => state.pixie.navigation.showScroll);

  return (
    <>
      <div className='absolute h-screen w-screen -z-10 bg-backgroundPixie' />
      <section
        className={`
        flex flex-col items-center justify-center 
        flex-grow relative w-full
        animate__animated animate__fadeIn animate__fast
        h-full
      `}
      >
        <div className='w-full flex h-full'>
          {!hiddenNavigation && session
           &&
           <div className={`${minimize ? 'w-1/12' : 'w-1/4'} `}>
             <Sidebar className={`fixed h-full overflow-y-auto ${minimize ? 'w-1/12' : 'w-1/4'}`}/>
           </div>
          }

          <div id='containerTool' className={`${hiddenNavigation || !session ? 'w-full' : minimize ? 'w-11/12' : 'w-3/4'} 
          lg:pl-11 lg:pr-11 z-10 bg-backgroundPixie h-full relative
            ${showScroll ? 'overflow-auto' : 'overflow-hidden'}
          `} >
            {(!hiddenNavigation && session) && <Navbar />}
            <>{children}</>
          </div>
        </div>
      </section>
    </>
    // <div className='h-full w-full bg-backgroundPixie'>
    //   <div className='flex justify-center'>
    //     <div className='flex max-w-[1440px] w-full'>
    //       {!hiddenNavigation && session
    //       &&
    //       <div className={`${minimize ? 'w-1/12' : 'w-1/4'}`}>
    //         <div className={`${minimize ? 'w-1/12' : 'w-1/4'} fixed h-full`}>
    //           <Sidebar />
    //         </div>
    //       </div>
    //       }
    //       <div className={`${hiddenNavigation || !session ? 'w-full' : minimize ? 'w-11/12' : 'w-3/4'} bg-backgroundPixie h-full`}>
    //         {(!hiddenNavigation && session) && <Navbar />}
    //         { children }
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

interface ContainerPageProps {
  children: JSX.Element
}

export default ContainerPage;
