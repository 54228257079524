import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SideBarItemSkelenton = () => {
  const filas = [];
  for (let index = 0; index < 4; index++)
    filas.push(
      <div className='grid grid-cols-[0.2fr,1fr] items-center ml-3 mr-7 mb-6' key={`sidebarItem-${index}`}>
        <Skeleton circle={true} width={40} height={40} className='w-1/5'/>
        <Skeleton className='w-4/5' />
      </div>
    );

  return (
    <div className='flex flex-col justify-between'>
      {filas}
    </div>
  );
};

export default SideBarItemSkelenton;
