import { lazy } from 'react';

import { settingsPage } from '../../../interfaces/configPages';

const ShippingCityPage = lazy(() => import('.'));

const ShippingCityPageConfig: settingsPage = {
  routes: [
    {
      path: '/logistics/shipping',
      element: <ShippingCityPage />
    },
  ],
};

export default ShippingCityPageConfig;
