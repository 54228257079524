import { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import ContainerPage from '../components/layout/containerPage';
import Auth from '../auth/Auth';
import routes from './routesConfig';

const Router = () => (
  <BrowserRouter>
    {/* check the user in each rute change */}
    <Auth>
      <ContainerPage>
        {/* show loading while the pages loading */}
        <Suspense fallback={<span></span>}>
          <Routes>
            {routes.map(item =>
              <Route path={item.path} element={item.element} key={item.path}/>
            )}
            <Route
              path='*'
              element={
                <Navigate to='/auth/login' />
              }
            />
          </Routes>
        </Suspense>
      </ContainerPage>
    </Auth>
  </BrowserRouter>
);

export default Router;
