import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { CitiesListSliceData, City, Delegation, DelegationForm, initialStateCitiesSlice } from '../../../interfaces/cities';

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

type ModalMode = CitiesListSliceData['popupMode'];

export const showPopupContentCities = (city: City | undefined, mode: ModalMode) => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (city) {
        dispatch(setCitiesPopupContent(city));
        dispatch(setDetailPopupModeCities('edit'));
      }

      break;
    case 'create':
      dispatch(setCitiesPopupContent(undefined));
      dispatch(setDetailPopupModeCities('create'));
      break;
    case 'view':
      if (city) {
        dispatch(setCitiesPopupContent(city));
        dispatch(setDetailPopupModeCities('view'));
      }

      break;
    default:
      break;
  }

  return dispatch(setShowPopupContentCities(true));
};

export const showPopupMessage = (city: City) => async (dispatch: AppDispatch) => {
  dispatch(setCitiesPopupContent(city));
  return dispatch(setShowPopupMessage(true));
};

export const showPopupDeleteCity = (city: City) => async (dispatch: AppDispatch) => {
  dispatch(setCitiesPopupContent(city));
  return dispatch(setShowPopupDeleteCities(true));
};

export const initialDelegation = {
  deleteZipCodes: [],
  initialZipCodes: [],
  name: '',
  zipCodes: [],
  zipCodesOp: [],
  id: `delegation${Math.floor(Math.random() * 90000000) + 10000000}`,
  error: ''
};

const initialState: initialStateCitiesSlice = {
  reloadData: false,
  showPopupContent: false,
  detailPopupMode: 'edit',
  messagePopupMode: false,
  showPopupDeleteCities: false,
  permissions,
  delegacion: [initialDelegation]
};

const citiesSlice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    setReloadDataCities(state, action: PayloadAction<boolean>) {
      state.reloadData = action.payload;
    },
    setShowPopupContentCities(state, action: PayloadAction<boolean>) {
      state.showPopupContent = action.payload;
    },
    setCitiesPopupContent(state, action: PayloadAction<City | undefined>) {
      state.citiesPopupContent = action.payload;
    },
    setShowPopupMessage(state, action: PayloadAction<boolean>) {
      state.messagePopupMode = action.payload;
    },
    setShowPopupDeleteCities(state, action: PayloadAction<boolean>) {
      state.showPopupDeleteCities = action.payload;
    },
    setDetailPopupModeCities(state, action:PayloadAction<'edit' | 'create' | 'view'>) {
      state.detailPopupMode = action.payload;
    },
    setPermissionsCities(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
    setDelegations(state, action: PayloadAction<DelegationForm[]>) {
      state.delegacion = action.payload;
    }
  },
});

export const {
  setShowPopupMessage,
  setShowPopupDeleteCities,
  setShowPopupContentCities,
  setReloadDataCities,
  setPermissionsCities,
  setDetailPopupModeCities,
  setCitiesPopupContent,
  setDelegations
} = citiesSlice.actions;

export default citiesSlice.reducer;
