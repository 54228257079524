import { SelectItem } from '../components/form/selectField';
import { CartItem } from './product';
import { subscriptionUserComplete } from './subscriptions';

export interface searchOrders {
  seller: string,
  email: string,
  phone: string,
  orderNumber: string,
  orderDateStart: string,
  orderDateEnd: string,
  shippingDateStart: string,
  shippingDateEnd: string,
  cities: SelectItem[],
  city: SelectItem,
  paymentsState: SelectItem[],
  paymentState: SelectItem,
}

export interface searchOrdersValidate {
  phone: string,
  orderNumber: string,
  orderDateStart: string,
  orderDateEnd: string,
  shippingDateStart: string,
  shippingDateEnd: string,
}

export interface getOrdersApi {
  created_at?: string,
  customer_id: string,
  id: string,
  order_detail: order_detail,
  purchase_orders_status_id: string,
  delivery_statuses_id: string,
  transaction_id: string,
  delivery_date?: string | null,
  updated_at?: string,
  delivery_number?: string | null,
  internal_order_id: null | string
  delivery_status: {
    key: string,
  },
  purchase_order_status: {
    key: string,
  }
  transaction: {
    created_at: string,
    customer_id: string,
    id: string,
    tiket_number: string,
    transaction_reference: string,
    updated_at: string
  },
  orderDataST?: orderDataST,
  user_subscription_id?: string,
  purchase_order_subscription?: subscriptionUserComplete
}

export interface order_detail {
  items: itemsMP[],
  payer: billingDetailsInterfaceMV,
  auto_return: 'approved' | '',
  shipments : {
    cost: number,
    mode: string,
    receiver_address: {
      zip_code: string,
      street_name: string,
      street_number: string,
      floor: string,
      apartment: string,
      city_name: string,
      state_name: string,
      country_name: string
    }
  },
  metadata: {
    shippingDetails: houseData,
    details_payments: {
      subTotalNoIva: number,
      deliveryPrice: number,
      onlyIva: number,
      totalPayment: number
      numberShipping?: number,
      purchasePaymentRef?: number,
      purchaseShippingRef?: number,
      acessoriesPrice?: number
    },
    contactDetails: {
      email: string,
      firstName: string,
      lastName: string,
      phoneNumber: string,
    },
    billingDetails: houseData,
    customer_id: string,
    delivery_note?: string,
    couponId?: {
      id: string,
      amount: number,
      code: string,
      internalCouponName: string,
      internalCouponNumber: string
    },
    referred?: {
      amount: number,
      code: string,
      email: string,
      id: string
    },
    dataConstancy?: {
      businessName: string,
      Rfc: string,
      postalCode: string,
      regime: string
      pdfBill: string
    },
    products: CartItem[],
    accessoriesSubscrptionValue?: number
  }
  total_amount: number,
  cellar_id: string,
  country_id: string,
  city_id: string,
  back_urls: {
    failure: string,
    pending: string,
    success: string
  },
  client_id: string,
  collector_id: string,
}

export interface houseData {
  name: string,
  phone: string,
  address1: any,
  city: string,
  region: string,
  country: string,
  cityValue: string,
  regionvalue: string,
  countryValue: string
}

export interface itemsMP {
  id: string,
  title: string,
  description: string,
  picture_url: string,
  quantity: number,
  unit_price: number,
}

export interface itemsST {
  id: string,
  stripeId: string,
  name: string,
  amount: number,
  quantity: number,
  description: string,
  picture_url: string
}

export interface billingDetailsInterfaceMV {
  name: string,
  surname: string,
  email: string,
  phone: {
    number: string,
    area_code: string
  },
  address: {
    zip_code: string,
    street_name: string,
    street_number: number
  }
}

export interface excelOrders {
  OrdenNº: string,
  Estado: string,
  EstadoDelPago: string,
  FechaOrden: string,
  FechaEntrega: string,
  Direccion: string | undefined,
  NumeroExterior: string,
  NumeroInterior:string,
  Referencias:string,
  codigoPostal:string,
  Colonia:string,
  Municipio: string,
  Pais: string,
  Ciudad: string,
  Cliente: string,
  Email: string,
  Telefono: string,
  Valor: number,
  Productos: string
}

export type product = itemsMP;

export interface orderEdit {
  status: string,
  deliveryDate: string,

  address: string,
  houseNumber: string,
  reference: string,
  apartament: string,
  zipCode: SelectItem,
  zipCodes: SelectItem[],
  colony: string,
  city: SelectItem,
  cities: SelectItem[],
  state: SelectItem,
  states: SelectItem[]
  stateST: string,
  country?: SelectItem,
  countries?: SelectItem[]
}

export interface orderEditWholesaler {
  status: string,
  purchaseStatus: string,
  deliveryDate: string,

  address: string,
  houseNumber: string,
  reference: string,
  apartament: string,
  zipCode: SelectItem,
  zipCodes: SelectItem[],
  colony: string,
  city: SelectItem,
  cities: SelectItem[],
  state: SelectItem,
  states: SelectItem[]
  stateST: string,
  country?: SelectItem,
  countries?: SelectItem[]

  type_payment?: string,
  total_credit_used?: string,
}

export interface orderEditValidate {
  deliveryDate: string,
  address: string,
  houseNumber: string,
  reference: string,
  apartament: string,
  zipCode: string,
  colony: string,
  city: string,
  state: string,
}

export interface orderEditValidateWholesaler extends orderEditValidate {
  type_payment?: string,
  total_credit_used?: string,
}


export interface deliveryStatus {
  created_at: string,
  description: string,
  id: string,
  key: string,
  updated_at: string,
  value: number
}

export interface purchaseStatus {
  description: string,
  id: string,
  key: string,
}

export interface getOrdersStripe {
    id: string,
    ticket_number: string,
    customer_id: string,
    delivery_date: null,
    delivery_number: null,
    metadata: {
      products: itemsST[],
      productsComplete: CartItem[],
      email: string,
      couponId?: {
        id: string,
        amount: number,
        code: string,
        internalCouponName: string,
        internalCouponNumber: string
      },
    },
    order_detail: orderDataST,
    updated_at: string,
    created_at: string,
    purchase_orders_status_id: string,
    delivery_statuses_id: string,
    delivery_status: {
        created_at: string,
        description: string,
        id: string,
        key: string,
        updated_at: string,
        value: number
    },
    purchase_order_status: {
        created_at: string,
        description: string,
        id: string,
        key: string,
        updated_at: string,
        value: number
    }
}

export interface editDataStripe {
  delivery_statuses_id: string,
  delivery_date?: string,
  order_detail: orderDataST,
}

export interface orderDataST {
  id: string,
  url: string,
  mode: string,
  object: string,
  status: string,
  created: string,
  currency: string,
  cellar_id: string,
  country_id: string,
  city_id: string,
  metadata: {
      ticket: string,
      couponId?: {
        id: string,
        code: string,
        amount: number,
        internalCouponName: string,
        internalCouponNumber: string
      }
  },
  expires_at: number,
  amount_total: number,
  total_details: {
      amount_tax: number,
      amount_discount: number,
      amount_shipping: number
  },
  customer_details?: {
    name: string,
    email: string,
    phone: string,
    address: {
      city: string,
      line1: string,
      line2: string,
      state: string,
      country: string,
      postal_code: string
    },
    tax_exempt: string,
    tax_ids:[]
  },
  shipping_details?: {
    name: string,
    address: {
      city: string,
      line1: string,
      line2: string,
      state: string,
      country: string,
      postal_code: string
    }
  },
  payment_status: string,
  amount_subtotal: number,
  customer_creation: string,
  client_reference_id: string,
  payment_method_collection: string,
  billing_address_collection: string,
}

export const initOrderDetail: order_detail = {
  payer: {
    address: {
      street_name: '',
      street_number: 0,
      zip_code: ''
    },
    email: '',
    name: '',
    phone: {
      area_code: '',
      number: ''
    },
    surname: ''
  },
  shipments: {
    cost: 0,
    mode: '',
    receiver_address: {
      apartment: '',
      city_name: '',
      country_name: '',
      floor: '',
      state_name: '',
      street_name: '',
      street_number: '',
      zip_code: '',
    }
  },
  total_amount: 0,
  auto_return: '',
  back_urls: {
    failure: '',
    pending: '',
    success: ''
  },
  items: [{
    id: '',
    description: '',
    picture_url: '',
    quantity: 0,
    title: '',
    unit_price: 0
  }],
  metadata: {
    billingDetails: {
      address1: '',
      city: '',
      country: '',
      name: '',
      phone: '',
      region: '',
      cityValue: '',
      countryValue: '',
      regionvalue: ''
    },
    contactDetails: {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
    customer_id: '',
    details_payments: {
      deliveryPrice: 0,
      onlyIva: 0,
      subTotalNoIva: 0,
      totalPayment: 0,
    },
    shippingDetails: {
      // eslint-disable-next-line no-useless-escape
      address1: '{\"address\":\"cra 34 d 56 - 78\",\"houseNumber\":\"3456\",\"apartament\":\"\",\"reference\":\"\",\"zipCode\":\"05269\",\"colony\":\"bogota\"}',
      city: '',
      country: '',
      name: '',
      phone: '',
      region: '',
      cityValue: '',
      countryValue: '',
      regionvalue: ''
    },
    products: []
  },
  client_id: '',
  collector_id: '',
  cellar_id: '',
  city_id: '',
  country_id: ''
};

export const initOrder: getOrdersApi = {
  created_at: '',
  internal_order_id: '',
  customer_id: '',
  id: '',
  order_detail: initOrderDetail,
  purchase_orders_status_id: '',
  delivery_statuses_id: '',
  transaction_id: '',
  delivery_date: null,
  delivery_number: null,
  delivery_status: {
    key: '',
  },
  purchase_order_status: {
    key: ''
  },
  transaction: {
    created_at: '',
    customer_id: '',
    id: '',
    tiket_number: '',
    transaction_reference: '',
    updated_at: ''
  }
};

export interface addressObject {
  address: string | undefined,
  houseNumber: string | undefined,
  apartament: string | undefined,
  reference: string | undefined,
  zipCode: string | undefined,
  colony: string | undefined,
}
export interface WholesalerOrder {
  created_at: string;
  wholesaler_id: string;
  id: string;
  order_detail: OrderdetailWholesaler;
  purchases_order_status_id: string;
  delivery_status_id: string;
  updated_at: string;
  delivery_date?: any;
  internal_order_id: string;
  total_order_amount: string;
  wholesaler_email: string;
  cellar_id: number;
  url_support?: any;
  type_payment: string;
  total_credit_used: number;
  cellar: Cellar;
  purchase_order_status: Purchaseorderstatus;
  delivery_status: Purchaseorderstatus;
}

interface Purchaseorderstatus {
  description: string;
  id: string;
  key: string;
  value: number;
  created_at: string;
  updated_at: string;
}

interface Cellar {
  id: number;
  status: number;
  createAt: string;
  updateAt: string;
  name: string;
  address: string;
  phone: string;
  city_id: number;
  primary: number;
  isForWholesaler: boolean;
  price_list_id: string;
}

export interface OrderdetailWholesaler {
  items: Item[];
  city_id: number;
  metadata: Metadata;
  country_id: number;
}

interface Metadata {
  products: Product2[];
  customer_id: string;
  delivery_note: string;
  billingDetails: BillingDetails;
  contactDetails: ContactDetails;
  shippingDetails: BillingDetails;
  details_payments: Detailspayments;
}

interface Detailspayments {
  onlyIva: number;
  totalPayment: number;
  deliveryPrice: number;
  subTotalNoIva: number;
  numberShipping: number;
  purchasePaymentRef: number;
  purchaseShippingRef: number;
}

interface ContactDetails {
  email: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
}

interface BillingDetails {
  city: string;
  name: string;
  phone: string;
  region: string;
  country: string;
  address1: Address1;
  cityValue: string;
  regionvalue: string;
  countryValue: string;
}

interface Address1 {
  colony: string;
  address: string;
  zipCode: string;
  reference: string;
  apartament: string;
  houseNumber: string;
}

interface Product2 {
  product: Product;
  quantity: number;
}

interface Product {
  id: number;
  age: string;
  key: string;
  name: string;
  dosis: string;
  price: string;
  key_en: string;
  status: string;
  License: string;
  country: number;
  name_en: string;
  benefits: string[];
  category: string;
  dosis_en: string;
  kind_pet: string;
  quantity: number;
  stripeId?: any;
  atributos: string[];
  url_image: string;
  created_at: string;
  updated_at: string;
  benefits_en?: any[];
  description: string;
  ingredients: string[];
  atributos_en?: any[];
  presentation: string;
  description_en?: string;
  ingredients_en?: any[];
  nutrition_information: string[];
  recommendation_for_use: string;
  nutrition_information_en?: any[];
  recommendation_for_use_en?: string;
  isFavorite?: boolean;
  subscriptionShippingOneTime?: string;
}

interface Item {
  id: number;
  title: string;
  quantity: number;
  unit_price: number;
  description: Description;
  picture_url: string;
}

interface Description {
  age: string;
  presentation: string;
}
