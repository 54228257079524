/* eslint-disable complexity */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setDataLoad } from '../../auth/store/loginSlice';
import { sidebarConfig, sidebarConfigInterface } from '../../interfaces/sideBar';
import { returnRouterObject } from '../../helper/permissionsHelper';
import { getPermissionsApi, permissionOptions } from '../../interfaces/auth';
import { AppDispatch } from '../../store';
import { setPermissionsInventory } from '../../store/pixie/views/inventorySlice';
import { setPermissionsOrderFinder } from '../../store/pixie/views/orderFinderSlice';
import { setPermissionsProfile } from '../../store/pixie/views/profileSlice';
import { setPermissionsRoles } from '../../store/pixie/views/rolesSlice';
import { setPermissionsCoupons } from '../../store/pixie/views/couponsSlice';
import { setPermissionsProducts } from '../../store/pixie/views/productSlice';
import { setPermissionsSubscription } from '../../store/pixie/views/SubscriptionSlice';
import { setPermissionsSubscriptionUser } from '../../store/pixie/views/SubscriptionUserSlice';
import { setPermissionsCalendar } from '../../store/pixie/views/calendarSlice';
import { setPermissionsUserEcommerce } from '../../store/pixie/views/userEcommerceSlice';
import { setPermissionsTestimonials } from '../../store/pixie/views/testimonialSlice';
import { setPermissionsAttribute } from '../../store/pixie/views/attributesSlice';
import { setPermissionsCities } from '../../store/pixie/views/citiesSlice';
import { setCellarsPermissions } from '../../store/pixie/views/cellarsSlice';
import { setPermissionsShippingCity } from '../../store/pixie/views/shippingCitySlice';
import { setPermissionsPriceList } from '../../store/pixie/views/priceListSlice';
import { setPermissionsReports } from '../../store/pixie/views/reportSlice';
import { setPermissionsContentManager } from '../../store/pixie/views/contentManagerSlice';
import { setwholeSalerPermissions } from '../../store/pixie/views/wholeSalerSlice';
import { setPermissionsGift } from '../../store/pixie/views/giftSlice';
import { setRecomendationPermissions } from '../../store/pixie/views/recomendationSlice';

// This function init the routes and show items depending the rol
export const setInitialRoutesAndPermissions = (data: getPermissionsApi[]) => (dispatch: AppDispatch) => {
  // Load routes
  const routerObject = returnRouterObject(data);
  // Load permissions
  data.forEach(({ create, read, upload, delete: deleteOP, module }) => {
    const permissions: permissionOptions = {
      create,
      delete: deleteOP,
      read,
      upload
    };

    if (module.route?.includes('/inventory/product'))
      dispatch(setPermissionsInventory(permissions));
    if (module.route?.includes('/users/team'))
      dispatch(setPermissionsProfile(permissions));
    if (module.route?.includes('/users/role'))
      dispatch(setPermissionsRoles(permissions));
    if (module.route?.includes('/users/recommendations'))
      dispatch(setRecomendationPermissions(permissions));
    if (module.route?.includes('/logistics/purchases'))
      dispatch(setPermissionsOrderFinder(permissions));
    if (module.route?.includes('/coupons'))
      dispatch(setPermissionsCoupons(permissions));
    if (module.route?.includes('/products/view'))
      dispatch(setPermissionsProducts(permissions));
    if (module.route?.includes('/subscription/list'))
      dispatch(setPermissionsSubscription(permissions));
    if (module.route?.includes('/subscription/user/list'))
      dispatch(setPermissionsSubscriptionUser(permissions));
    if (module.route?.includes('/calendar'))
      dispatch(setPermissionsCalendar(permissions));
    if (module.route?.includes('/ecommerce/users')) {
      dispatch(setPermissionsUserEcommerce(permissions));
      dispatch(setwholeSalerPermissions(permissions));
    }

    if (module.route?.includes('/testimonials'))
      dispatch(setPermissionsTestimonials(permissions));
    if (module.route?.includes('/products/atributes'))
      dispatch(setPermissionsAttribute(permissions));
    if (module.route?.includes('/products/gift'))
      dispatch(setPermissionsGift(permissions));
    if (module.route?.includes('/logistics/cities'))
      dispatch(setPermissionsCities(permissions));
    if (module.route?.includes('/inventory/stores'))
      dispatch(setCellarsPermissions(permissions));
    if (module.route?.includes('/logistics/shipping'))
      dispatch(setPermissionsShippingCity(permissions));
    if (module.route?.includes('/products/listprice'))
      dispatch(setPermissionsPriceList(permissions));
    if (module.route?.includes('/reports'))
      dispatch(setPermissionsReports(permissions));
    if (module.route?.includes('/contentmanager'))
      dispatch(setPermissionsContentManager(permissions));
  }
  );
  dispatch(setDataLoad());
  return dispatch(changeConfigurationSideBar(routerObject));
};

const initialConfigRouter: sidebarConfigInterface[] = sidebarConfig;

const initialState = {
  configRouter: initialConfigRouter
};

const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    changeConfigurationSideBar(state, action: PayloadAction<sidebarConfigInterface[]>) {
      state.configRouter = action.payload;
    }
  },
  extraReducers: {},
});

export const { changeConfigurationSideBar } = routerSlice.actions;

export default routerSlice.reducer;
