import { lazy } from 'react';

import { settingsPage } from '../../interfaces/configPages';

const MetricPage = lazy(() => import('.'));

const MetricPageConfig: settingsPage = {
  routes: [
    {
      path: '/metrics',
      element: <MetricPage />
    },
  ],
};

export default MetricPageConfig;
