import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '..';
import { sidebarConfigInterface } from '../../interfaces/sideBar';
import { changeObjectRoute } from '../../helper/navigationHelper';
import { changeConfigurationSideBar } from '../../router/store/routerSlice';

export const updateRouteAndTitle = (pathname: string, configRoutesByRole : sidebarConfigInterface[]) => async (dispatch: AppDispatch) => {
  // Find object
  const routeComplete = configRoutesByRole.find(route =>
    route?.children
      ?
      route.children.find(child =>
        child.url === pathname
      )
      :
      route.url === pathname
  );

  // Set history and title
  const history = `${routeComplete?.children
    ? routeComplete.name + ' > ' + routeComplete.children.map(child => (child.url === pathname) ? child.name : '')
    : routeComplete?.name
  }`;
  const title = `${routeComplete?.children
    ? routeComplete.children.map(child => (child.url === pathname) ? child.name : '')
    : routeComplete?.name
  }`;

  // Update title and history
  dispatch(setTitlePage(title.replace(/,/g, '')));
  return dispatch(setHistoryNavigation(history.replace(/,/g, '')));
};

// Change Color sidebar active
export const changeSidebarSelected = (pathname: string, configRoutesByRole : sidebarConfigInterface[]) => (dispatch: AppDispatch) => {
  let indexParent = -1;
  let indexChildren = -1;
  // Find object
  const routeComplete = configRoutesByRole.find((route, index) =>
    route?.children
      ?
      route.children.find((child, indexChild) => {
        if (child.url === pathname) {
          indexParent = index;
          indexChildren = indexChild;
          return true;
        }

        return false;
      }
      )
      :
      // Function with autoCall
      (function () {
        if (route.url === pathname) {
          indexParent = index;
          return true;
        }

        return false;
      })()
  );
  // Create new Object and send
  const newObjectRoute = changeObjectRoute(routeComplete, configRoutesByRole, indexChildren, indexParent);
  return dispatch(changeConfigurationSideBar(newObjectRoute));
};

const initialState = {
  hidden: false,
  minimize: false,
  historyNavigation: '',
  title: '',
  showScroll: true
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setTitlePage(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    setHistoryNavigation(state, action: PayloadAction<string>) {
      state.historyNavigation = action.payload;
    },
    setHiddenNavigation(state, action: PayloadAction<boolean>) {
      state.hidden = action.payload;
    },
    setMinimizeNavigation(state, action: PayloadAction<boolean>) {
      state.minimize = action.payload;
    },
    setShowScroll(state, action: PayloadAction<boolean>) {
      state.showScroll = action.payload;
    }
  },
});

export const { setHiddenNavigation, setMinimizeNavigation, setTitlePage, setHistoryNavigation, setShowScroll } = navigationSlice.actions;

export default navigationSlice.reducer;
