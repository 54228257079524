import { useDispatch } from 'react-redux';
import { setMinimizeNavigation } from '../../../store/pixie/navigationSlice';

import SidebarItem from '../../common/sidebarItem';

import { useAppSelector } from '../../../hooks';
import { pixieBlack } from '../../../assets/images';
import { doubleLeft } from '../../../assets/vectors';
import IconButton from '../../common/iconButton';
import SideBarItemSkelenton from '../../skeletonLoadings/sideBarItemSkeleton';

const Sidebar = ({ className }: SidebarProps) => {
  const dispatch = useDispatch();
  const minimize = useAppSelector(state => state.pixie.navigation.minimize);
  const routes = useAppSelector(state => state.router.router.configRouter);

  const closeOrOpenSidebar = () => dispatch(setMinimizeNavigation(!minimize));

  return (
    <div className={`flex flex-col w-full h-full bg-[#D9EEEF] max-w-[400px] max-h-[1600px] ${className}`}>
      <div className='flex justify-between pl-5'>
        <img src={pixieBlack} alt={pixieBlack} className='w-[60px] h-[60px] mt-5'/>
        {minimize ?
          <div className='bg-secondary w-[30px] h-[30px] xl:left-[7.5rem] lg:left-[5.35rem] lg2:left-24 xl1:left-[6.7rem] xl2:left-[7.4rem]'>
            <img src={doubleLeft} alt={doubleLeft} className='w-[30px] text-grayPixie rotate-180' onClick={closeOrOpenSidebar}/>
          </div>
          :
          <div className='bg-secondary w-[30px] h-[30px]'>
            <img src={doubleLeft} alt={doubleLeft} className='w-7 h-7 text-grayPixie pl-[2px]' onClick={closeOrOpenSidebar}/>
          </div>
        }
      </div>
      <div className='flex flex-col justify-between h-full mt-[2rem]'>
        <div className='pl-5'>
          {routes[0].name
            ?
            <>
              {routes.map(item =>
                <SidebarItem
                  item={item}
                  key={`sidebarItem-${item.name}`}
                />
              )}
            </>
            :
            <SideBarItemSkelenton />
          }
        </div>
        { !minimize &&
        <div className='bg-secondary text-left pl-5 pt-5 pb-6 flex justify-between leading-none mt-16'>
          <p>
            <strong>Pixie SAS</strong> <br/>
            <span className='text-sm'>
              Nit 12345678-9 <br/>
              atencion@pixie.com <br/>
              Cel: 300 2345677 - 310 2345678
            </span>
          </p>
          <div className='flex justify-center'>
            <IconButton name='edit' size='lg' onClick={() => {}} shadow={false} className='text-[#4A4A4A]' sizeContainer='w-12 h-[1.5rem]'/>
          </div>
        </div>

        }
      </div>
    </div>
  );
};

interface SidebarProps {
  className?: string,
}

export default Sidebar;
