import { lazy } from 'react';

import { settingsPage } from '../../../interfaces/configPages';

const PricelistPage = lazy(() => import('.'));

const PricelistPageConfig: settingsPage = {
  routes: [
    {
      path: '/products/listprice',
      element: <PricelistPage />
    },
  ],
};

export default PricelistPageConfig;
