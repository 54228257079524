import { sidebarConfigInterface } from '../interfaces/sideBar';

// Create new object route config
export const changeObjectRoute = (routeObject: sidebarConfigInterface | undefined, objectRouterComplete: sidebarConfigInterface[], childrenIndex: number, parentIndex: number) : sidebarConfigInterface[] => {
  // Temporal values
  const objectRouterCompleteTemp = resetRouterStatus(objectRouterComplete);
  let routeObjectTemp = resetObjectStatus(routeObject);

  // If the route not has children
  if (childrenIndex === -1 && routeObject)
    routeObjectTemp = { ...routeObjectTemp, active: true };
  // If the parent has children
  else if (routeObjectTemp) {
    const childrensTemp = routeObjectTemp?.children;
    if (childrensTemp) {
      // Change parent status
      routeObjectTemp = { ...routeObjectTemp, active: true };
      // Change child status
      childrensTemp[childrenIndex] = { ...childrensTemp[childrenIndex], active: true };
      routeObjectTemp = { ...routeObjectTemp, children: childrensTemp };
    }
  }

  // Change the object
  objectRouterCompleteTemp[parentIndex] = routeObjectTemp;

  return objectRouterCompleteTemp;
};

// Reset the object complete
const resetRouterStatus = (objectRouterComplete: sidebarConfigInterface[]) : sidebarConfigInterface[] => {
  const objectRouterCompleteTemp: sidebarConfigInterface[] = JSON.parse(JSON.stringify(objectRouterComplete));
  objectRouterCompleteTemp.forEach(sideBarItem => {
    sideBarItem.active = false;
    // eslint-disable-next-line no-return-assign
    sideBarItem.children?.forEach(children => children.active = false);
  });

  return objectRouterCompleteTemp;
};

// Reset the new object if has children
const resetObjectStatus = (routeObject: sidebarConfigInterface | undefined): sidebarConfigInterface => {
  const routeObjectTemp: sidebarConfigInterface = routeObject ? JSON.parse(JSON.stringify(routeObject)) : [];
  // eslint-disable-next-line no-return-assign
  routeObjectTemp.children?.forEach(children => children.active = false);

  return routeObjectTemp;
};
