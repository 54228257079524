import { lazy } from 'react';

import { settingsPage } from '../../../interfaces/configPages';

const SubscriptionPage = lazy(() => import('.'));

const UserListSubscriptionPageConfig: settingsPage = {
  routes: [
    {
      path: '/subscription/user/list',
      element: <SubscriptionPage />
    },
  ],
};

export default UserListSubscriptionPageConfig;
