import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { getOrdersApi, initOrder } from '../../../interfaces/orders';

export const showPopupEditOrder = (orders: getOrdersApi) => async (dispatch: AppDispatch) => {
  dispatch(setOrderPopupContent(orders));
  return dispatch(setShowPopupEditOrder(true));
};

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState = {
  reloadOrders: false,
  showPopupEditOrder: false,
  orderPopupContent: initOrder,
  permissions
};

const orderFilterSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setReloadDataOrder(state, action: PayloadAction<boolean>) {
      state.reloadOrders = action.payload;
    },
    setShowPopupEditOrder(state, action: PayloadAction<boolean>) {
      state.showPopupEditOrder = action.payload;
    },
    setOrderPopupContent(state, action: PayloadAction<getOrdersApi>) {
      state.orderPopupContent = action.payload;
    },
    setPermissionsOrderFinder(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
  },
});

export const { setReloadDataOrder, setShowPopupEditOrder, setOrderPopupContent, setPermissionsOrderFinder } = orderFilterSlice.actions;

export default orderFilterSlice.reducer;
