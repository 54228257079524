import { combineReducers } from '@reduxjs/toolkit';
import navigation from './navigationSlice';
import profile from './views/profileSlice';
import inventory from './views/inventorySlice';
import role from './views/rolesSlice';
import search from './searchSlice';
import order from './views/orderFinderSlice';
import product from './views/productSlice';
import coupon from './views/couponsSlice';
import subscription from './views/SubscriptionSlice';
import subscriptionUser from './views/SubscriptionUserSlice';
import calendar from './views/calendarSlice';
import userEcommerceSlice from './views/userEcommerceSlice';
import testimonialSlice from './views/testimonialSlice';
import attribute from './views/attributesSlice';
import cities from './views/citiesSlice';
import shippingCity from './views/shippingCitySlice';
import cellars from './views/cellarsSlice';
import priceList from './views/priceListSlice';
import reports from './views/reportSlice';
import contentManager from './views/contentManagerSlice';
import wholeSaler from './views/wholeSalerSlice';
import whereBuySlice from './views/whereBuySlice';
import gift from './views/giftSlice';
import appointment from './views/appointmentSlice';
import recomendation from './views/recomendationSlice';

const pixieReducers = combineReducers({
  navigation,
  profile,
  inventory,
  role,
  search,
  order,
  product,
  coupon,
  subscription,
  subscriptionUser,
  calendar,
  userEcommerceSlice,
  testimonialSlice,
  attribute,
  cities,
  shippingCity,
  cellars,
  priceList,
  reports,
  contentManager,
  wholeSaler,
  gift,
  appointment,
  recomendation,
  whereBuySlice,
});

export default pixieReducers;
