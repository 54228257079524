import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { ProductComplete } from '../../../interfaces/inventory';

export const selectProductEdit = (user: ProductComplete) => async (dispatch: AppDispatch) => dispatch(setUserPopupContent(user));

const productEdit: ProductComplete = {
  id: 0,
  name: '',
  quantity: 0,
  category: '',
  age: ''
};

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState = {
  reloadInventory: false,
  productEdit,
  permissions
};

const productSlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setReloadDataInventory(state, action: PayloadAction<boolean>) {
      state.reloadInventory = action.payload;
    },
    setUserPopupContent(state, action: PayloadAction<ProductComplete>) {
      state.productEdit = action.payload;
    },
    setPermissionsInventory(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
  },
});

export const { setReloadDataInventory, setUserPopupContent, setPermissionsInventory } = productSlice.actions;

export default productSlice.reducer;
