import { iconUserDefault } from '../../../assets/vectors';
import { logOut } from '../../../auth/store/userSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import Skeleton from 'react-loading-skeleton';

const Navbar = () => {
  // Hooks
  const email = useAppSelector(state => state.auth.user.currentUser.email);
  const route = useAppSelector(state => state.pixie.navigation.historyNavigation);
  const title = useAppSelector(state => state.pixie.navigation.title);
  const dispatch = useAppDispatch();
  return (
    <div className='flex flex-col pt-8 pr-1 pl-14 text-[13px]'>
      <div className='flex justify-end items-center gap-[0.75rem] font-paragraph'>
        <img src={iconUserDefault} alt={iconUserDefault} className='w-[22px] h-[22px]'/>
        <span className='border-r border-black pr-4 py-[0.10rem]'>
          {email
            ?
            <>
              { email }
            </>
            :
            <Skeleton />
          }
        </span>
        <span className='cursor-pointer text-[#9B9B9B]' onClick={() => dispatch(logOut())}>Cerrar sesion</span>
      </div>
      <div className='flex flex-col justify-start items-start'>
        <h2 className='text-3xl font-titles text-primary'>{title}</h2>
        <span className='text-[13px]'>{route}</span>
      </div>
    </div>
  );
};

export default Navbar;
