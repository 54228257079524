/* eslint-disable no-negated-condition */
import { itemsMP, itemsST } from '../interfaces/orders';
import { CartItem, createProduct, productFullData } from '../interfaces/product';

export const initProduct:productFullData = {
  age: '',
  atributos: [],
  atributos_en: [],
  benefits: [],
  benefits_en: [],
  category: '',
  country: 1,
  description: '',
  description_en: '',
  dosis: '',
  dosis_en: '',
  id: 0,
  ingredients: [],
  ingredients_en: [],
  key: '',
  key_en: '',
  kind_pet: '',
  License: '',
  name: '',
  name_en: '',
  nutrition_information: [],
  nutrition_information_en: [],
  presentation: '',
  price: -1,
  quantity: -1,
  pack_price: 0,
  recommendation_for_use: '',
  recommendation_for_use_en: '',
  status: '1',
  url_image: ''
};

export const calculateTotal = (arrayProducts: CartItem[], withoutIva: boolean) => {
  let total = 0;
  arrayProducts.forEach(item => {
    total += (item.quantity * item.product.price);
  });

  if (withoutIva)
    return roundToXDigits(total - calculateIva(arrayProducts), 2);

  return total;
};

export const calculateIva = (arrayProducts: CartItem[]): number => {
  const total = calculateTotal(arrayProducts, false);

  return roundToXDigits(total * 0.16, 2);
};

export const roundToXDigits = (value: number, digits: number) => {
  // eslint-disable-next-line prefer-exponentiation-operator
  value *= Math.pow(10, digits);
  value = Math.round(value);
  // eslint-disable-next-line prefer-exponentiation-operator
  value /= Math.pow(10, digits);
  return value;
};

export const generateKeyProductSP = (productData: createProduct): string => {
  const jsonCountry = {
    1: 'mx',
    2: 'usa',
  };
  const productCountryKey = jsonCountry[productData.country as 1 | 2];
  let name = productData.name.replaceAll(' ', '-').toLocaleLowerCase();
  if (productData.age.includes(',')) {
    const splitAge = productData.age.split(',');
    splitAge.forEach(item => {
      name += `-${item.toLocaleLowerCase()}`;
    });
  } else
    name += `-${productData.age.toLocaleLowerCase()}`;
  return `${name}-${productCountryKey}`;
};

export const tranformProductsStripeToMp = (productsStripe: itemsST[]):itemsMP[] => {
  const productMP: itemsMP[] = productsStripe.map(item => {
    const productTemp: itemsMP = {
      description: item.description,
      id: item.id,
      picture_url: item.picture_url,
      quantity: item.quantity,
      title: item.name,
      unit_price: item.amount
    };
    return productTemp;
  });
  return productMP;
};

export const removeNonASCII = (str: string) => str.replace(/[^\x20-\x7E]/g, '');

export async function convertUrlToImageFile(url: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  const filename = url.substring(url.lastIndexOf('/') + 1);
  const file = new File([blob], filename, { type: blob.type });
  return file;
}

export const transformAge = (ages: string, language: 'es' | 'en') => {
  const agesArray = ages.split(',');
  const lower = agesArray.map(element => element.toLowerCase().trim());
  if (language === 'en')
    return transformToUsaAge(lower);

  return lower;
};

const transformToUsaAge = (ages: string[]): string[] => {
  const newArray: string[] = [];

  ages.forEach(element => {
    switch (element.toLowerCase()) {
      case 'cachorros':
        newArray.push('Puppies');
        break;

      case 'adultos':
        newArray.push('Adults');
        break;

      case 'senior':
        newArray.push('Senior');
        break;

      default:
        break;
    }
  });

  return newArray;
};

export const transformPrice = (price: string) => {
  const numericValue = price.replace(/[^\d.]/g, '');
  const parsedValue = parseFloat(numericValue);

  if (!isNaN(parsedValue)) {
    const parts = numericValue.split('.');
    let formattedValue = '';

    if (parts.length === 1)
      formattedValue = parsedValue.toLocaleString('en-US');
    else if (parts.length === 2) {
      const integerPart = parseFloat(parts[0]).toLocaleString('en-US');
      formattedValue = `${integerPart}.${parts[1]}`;
    }

    return formattedValue;
  }

  return '';
};
