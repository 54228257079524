import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { UserComplete } from '../../../interfaces/user';

export const showPopupContentProfile = (user: UserComplete) => async (dispatch: AppDispatch) => {
  dispatch(setUserPopupContent(user));
  return dispatch(setShowPopupContentProfile(true));
};

export const showPopupDeleteProfile = (user: UserComplete) => async (dispatch: AppDispatch) => {
  dispatch(setUserPopupContent(user));
  return dispatch(setShowPopupDelete(true));
};

const userPopupContent: UserComplete = {
  address: '',
  email: '',
  movil_phone: '',
  name: '',
  password: '',
  phone: '',
  position: '',
  id: '',
  accept_termns: 0,
  role: {
    id: '',
    title: '',
    permissions: []
  },
  status: 0
};

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState = {
  reloadProfile: false,
  showPopupContent: false,
  showPopupDelete: false,
  showCreateForm: false,
  userPopupContent,
  permissions
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setReloadData(state, action: PayloadAction<boolean>) {
      state.reloadProfile = action.payload;
    },
    setShowPopupCreate(state, action: PayloadAction<boolean>) {
      state.showCreateForm = action.payload;
    },
    setShowPopupContentProfile(state, action: PayloadAction<boolean>) {
      state.showPopupContent = action.payload;
    },
    setUserPopupContent(state, action: PayloadAction<UserComplete>) {
      state.userPopupContent = action.payload;
    },
    setShowPopupDelete(state, action: PayloadAction<boolean>) {
      state.showPopupDelete = action.payload;
    },
    setPermissionsProfile(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
  },
});

export const { setReloadData, setShowPopupContentProfile, setUserPopupContent, setShowPopupDelete, setShowPopupCreate, setPermissionsProfile } = profileSlice.actions;

export default profileSlice.reducer;
