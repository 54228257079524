import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { CalendarsDays, createEditNewDay, initialStateCalendarSlice } from '../../../interfaces/calendar';

export const showPopupEditCreateCalendar = (Calendar: CalendarsDays | undefined, mode: 'edit' | 'create' | 'view') => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (Calendar) {
        dispatch(setCalendarPopupContent(Calendar));
        dispatch(setDetailPopupMode('edit'));
      }

      break;
    case 'create':
      if (Calendar) {
        dispatch(setCalendarPopupContent(Calendar));
        dispatch(setDetailPopupMode('create'));
      } else {
        dispatch(setCalendarPopupContent(undefined));
        dispatch(setDetailPopupMode('create'));
      }

      break;
    case 'view':
      if (Calendar) {
        dispatch(setCalendarPopupContent(Calendar));
        dispatch(setDetailPopupMode('view'));
      }

      break;
    default:
      break;
  }

  return dispatch(setShowPopupContentcalendar(true));
};

export const showPopupCreateSlotCalendar = (Calendar: createEditNewDay) => async (dispatch: AppDispatch) => {
  dispatch(setCalendarSelectSlot(Calendar));
  dispatch(setDetailPopupMode('create'));

  return dispatch(setShowPopupContentcalendar(true));
};

export const showPopupDeleteCalendar = (daySelected: CalendarsDays) => async (dispatch: AppDispatch) => {
  dispatch(setCalendarPopupContent(daySelected));
  return dispatch(setShowPopupDeleteCalendar(true));
};

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState: initialStateCalendarSlice = {
  reloadCalendar: false,
  showPopupContentCalendar: false,
  detailPopupMode: 'edit',
  permissions,
  showPopupDeleteCalendar: false,
  calendarHolidaysList: []
};

const CalendarSlice = createSlice({
  name: 'Calendar',
  initialState,
  reducers: {
    setReloadDataCalendar(state, action: PayloadAction<boolean>) {
      state.reloadCalendar = action.payload;
    },
    setShowPopupContentcalendar(state, action: PayloadAction<boolean>) {
      state.showPopupContentCalendar = action.payload;
    },
    setCalendarPopupContent(state, action: PayloadAction<CalendarsDays | undefined>) {
      state.calendarPopupContent = action.payload;
    },
    setDetailPopupMode(state, action:PayloadAction<'edit' | 'create' | 'view'>) {
      state.detailPopupMode = action.payload;
    },
    setPermissionsCalendar(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
    setShowPopupDeleteCalendar(state, action: PayloadAction<boolean>) {
      state.showPopupDeleteCalendar = action.payload;
    },
    setCalendarSelectSlot(state, action: PayloadAction<createEditNewDay | undefined>) {
      state.calendarSelectSlot = action.payload;
    },
    setCalendarHolidaysList(state, action: PayloadAction<CalendarsDays[]>) {
      state.calendarHolidaysList = action.payload;
    },
  },
});

export const { setReloadDataCalendar,
  setCalendarPopupContent,
  setPermissionsCalendar,
  setDetailPopupMode,
  setShowPopupContentcalendar,
  setCalendarSelectSlot,
  setShowPopupDeleteCalendar,
  setCalendarHolidaysList
} = CalendarSlice.actions;

export default CalendarSlice.reducer;
