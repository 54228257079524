import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { getRolesApi } from '../../../interfaces/roles';

export const showPopupEditRole = (role: getRolesApi) => async (dispatch: AppDispatch) => {
  dispatch(setRolePopupContent(role));
  return dispatch(setShowPopupEditRole(true));
};

export const showPopupDeleteRole = (role: getRolesApi) => async (dispatch: AppDispatch) => {
  dispatch(setRolePopupContent(role));
  return dispatch(setShowPopupDeleteRole(true));
};

const rolePopupContent: getRolesApi = {
  description: '',
  id: '',
  title: '',
  key: ''
};

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState = {
  reloadRoles: false,
  reloadPermissions: false,
  showPopupEditRole: false,
  showPopupDeleteRole: false,
  showPopupCreateRole: false,
  showPopupEditPermissions: false,
  rolePopupContent,
  permissions
};

const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setReloadDataRole(state, action: PayloadAction<boolean>) {
      state.reloadRoles = action.payload;
    },
    setReloadDataPermissions(state, action: PayloadAction<boolean>) {
      state.reloadPermissions = action.payload;
    },
    setShowPopupEditRole(state, action: PayloadAction<boolean>) {
      state.showPopupEditRole = action.payload;
    },
    setRolePopupContent(state, action: PayloadAction<getRolesApi>) {
      state.rolePopupContent = action.payload;
    },
    setShowPopupDeleteRole(state, action: PayloadAction<boolean>) {
      state.showPopupDeleteRole = action.payload;
    },
    setShowPopupCreateRole(state, action: PayloadAction<boolean>) {
      state.showPopupCreateRole = action.payload;
    },
    setShowPopupEditPermissions(state, action: PayloadAction<boolean>) {
      state.showPopupEditPermissions = action.payload;
    },
    setPermissionsRoles(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
  },
});

export const { setReloadDataRole,
  setShowPopupEditRole,
  setRolePopupContent,
  setShowPopupDeleteRole,
  setShowPopupCreateRole,
  setShowPopupEditPermissions,
  setReloadDataPermissions,
  setPermissionsRoles
} = roleSlice.actions;

export default roleSlice.reducer;
