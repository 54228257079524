import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sidebarConfigInterface } from '../../../interfaces/sideBar';
import { selectImageSideBar } from '../../../helper/sideBarHelper';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setMinimizeNavigation } from '../../../store/pixie/navigationSlice';
import Icon from '../icon';

const SidebarItem = ({ item }: sidebarItemProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const minimize = useAppSelector(state => state.pixie.navigation.minimize);
  const [showChildren, setShowChildren] = useState(false);

  const sidebarOpen = () => {
    setShowChildren(!showChildren);
    if (minimize)
      dispatch(setMinimizeNavigation(!minimize));
  };

  useEffect(() => {
    if (minimize)
      setShowChildren(false);
  }, [minimize]);

  return (
    <ul className='ml-3 mr-7 mb-2'>
      <li
        className='flex flex-col font-titles cursor-pointer'
      >
        <div className='flex justify-between items-center'
          onClick={() => (item.url ? navigate(item.url) : sidebarOpen())}
        >
          <div className='flex gap-4 items-center'>
            <div className='bg-secondary rounded-full w-5 h-5 flex justify-center items-center p-[0.2rem]'>
              <img src={selectImageSideBar(item.name)} alt='imagesideBar' className='w-full h-full'/>
            </div>
            {!minimize && <span className={`${item.active && 'text-primary'} text-lg`}>{item.name}</span>}
          </div>
          {(item.children && !minimize) && (showChildren ? <Icon name='expand_less' size='2xl'/> : <Icon name='expand_more' size='2xl'/>)}
        </div>
        {(item.children && showChildren) &&
          (
            <ul className='flex flex-col ml-[3rem] mt-1 gap-[0.4rem] font-sanzBold'>
              {item.children.map(children => (
                <li className={children.active ? 'text-primary' : ''}
                  key={`children${item.name}-${children.name}`}
                  onClick={() => navigate(children.url)}
                >
                  {children.name}
                </li>
              ))}
            </ul>
          )}
      </li>
    </ul>
  );
};

interface sidebarItemProps {
  item: sidebarConfigInterface
}

export default SidebarItem;
