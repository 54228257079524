import { lazy } from 'react';

import { settingsPage } from '../../../interfaces/configPages';

const ProductPage = lazy(() => import('.'));

const ProductPageConfig: settingsPage = {
  routes: [
    {
      path: '/products/view',
      element: <ProductPage />
    },
  ],
};

export default ProductPageConfig;
