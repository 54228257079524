import { lazy } from 'react';

import { settingsPage } from '../../../interfaces/configPages';

const OrderFinderPage = lazy(() => import('.'));

const OrderFinderPageConfig: settingsPage = {
  routes: [
    {
      path: '/logistics/purchases',
      element: <OrderFinderPage />
    },
  ],
};

export default OrderFinderPageConfig;
