import { routesInterface, settingsPage } from '../interfaces/configPages';

import AuthPageConfig from '../views/auth/AuthPageConfig';
import DashboardPageConfig from '../views/dashboard/DashboardConfig';
import PixieUtils from '../utils/pixieUtils';
import OrderFinderPageConfig from '../views/orders/orderFinder/OrderFinderConfig';
import RolesPageConfig from '../views/roles/rolesPageConfig';
import MetricPageConfig from '../views/metric/MetricPageConfig';
import InventoryPageConfig from '../views/inventory/inventory/InventoryPageConfig';
import ProductPageConfig from '../views/product/view/ProductPageConfig';
import CouponsPageConfig from '../views/coupons/CouponsPageConfig';
// Subscriptions pages
import UserlistSubscriptionPageConfig from '../views/subscription/userListSubscription/UserListSubscriptionPageConfig';
import SubscriptionPageConfig from '../views/subscription/subscriptions/SubscriptionPageConfig';
import CalendarPageConfig from '../views/calendar/CalendarPageConfig';
import ReportPageConfig from '../views/reports/ReportsPageConfig';
import UsersEcoPageConfig from '../views/usersEcommerce/usersEcoPageConfig';
import TestimonialsPageConfig from '../views/testimonials/testimonialsPageConf';
import StorePageConfig from '../views/inventory/stores/StorePageConfig';
import AttributePageConfig from '../views/product/attributes/AttributePageConfig';
import CitiesPageConfig from '../views/orders/cities/CitiesPageConfig';
import ShippingCityPageConfig from '../views/orders/shippingCity/ShippingCityPageConfig';
import PricelistPageConfig from '../views/product/priceList/priceListPageConfig';
import GiftPageConfig from '../views/product/gift/GiftPageConfig';

// Content Manager
import ContentManagerPageConfig from '../views/contentManager/ContentManagerPageConfig';
import VetPageConfig from '../views/vet/UserListSubscriptionPageConfig';
import RecomendatiosPageConfig from '../views/recomendations/RecomendationConfig';

const routeConfigs: settingsPage[] = [
  AuthPageConfig,
  DashboardPageConfig,
  OrderFinderPageConfig,
  RolesPageConfig,
  MetricPageConfig,
  InventoryPageConfig,
  ProductPageConfig,
  CouponsPageConfig,
  UserlistSubscriptionPageConfig,
  SubscriptionPageConfig,
  CalendarPageConfig,
  ReportPageConfig,
  UsersEcoPageConfig,
  TestimonialsPageConfig,
  StorePageConfig,
  AttributePageConfig,
  CitiesPageConfig,
  ShippingCityPageConfig,
  PricelistPageConfig,
  ContentManagerPageConfig,
  GiftPageConfig,
  VetPageConfig,
  RecomendatiosPageConfig
];

const routes: routesInterface[] = PixieUtils.generateRoutesFromConfigs(routeConfigs);

export default routes;
