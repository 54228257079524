import { iconPerson, basketIcon, metricIcon, inventoryIcon, couponsIcon, productIcon, iconCalendar, calendarIconSideBar, problem, face, volume } from '../assets/vectors';

export const selectImageSideBar = (name: string): string => {
  switch (name) {
    case 'Perfiles':
      return iconPerson;
    case 'Pedidos':
      return basketIcon;
    case 'Métricas':
      return metricIcon;
    case 'Inventario':
      return inventoryIcon;
    case 'Cupones':
      return couponsIcon;
    case 'Productos':
      return productIcon;
    case 'Suscripciones':
      return iconCalendar;
    case 'Calendario':
      return calendarIconSideBar;
    case 'Reportes':
      return problem;
    case 'Usuarios':
      return face;
    case 'Testimonios':
      return volume;
    case 'Administrador de contenido':
      return volume;

    default:
      break;
  }

  return '';
};
