import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { infoGroupAttribute, infoSelectSPandEn, initialStateAttributeSlice } from '../../../interfaces/product';

export const showPopupContentAttribute = (attribute: infoSelectSPandEn | undefined, mode: 'edit' | 'create' | 'view') => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (attribute) {
        dispatch(setAttributePopupContent(attribute));
        dispatch(setDetailPopupModeAttribute('edit'));
      }

      break;
    case 'create':
      dispatch(setAttributePopupContent(undefined));
      dispatch(setDetailPopupModeAttribute('create'));
      break;
    case 'view':
      if (attribute) {
        dispatch(setAttributePopupContent(attribute));
        dispatch(setDetailPopupModeAttribute('view'));
      }

      break;
    default:
      break;
  }

  return dispatch(setShowPopupContentAttribute(true));
};

export const showPopupDeleteAttributes = (attribute: infoSelectSPandEn) => async (dispatch: AppDispatch) => {
  dispatch(setAttributePopupContent(attribute));
  return dispatch(setShowPopupDeleteAttributes(true));
};

export const showPopupContentAttributeGroup = (groupItem: infoGroupAttribute | undefined, mode: 'edit' | 'create' | 'view') => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (groupItem) {
        dispatch(setAttributeGroupPopupContent(groupItem));
        dispatch(setDetailPopupModeAttribute('edit'));
      }

      break;
    case 'create':
      dispatch(setAttributeGroupPopupContent(undefined));
      dispatch(setDetailPopupModeAttribute('create'));
      break;
    case 'view':
      if (groupItem) {
        dispatch(setAttributeGroupPopupContent(groupItem));
        dispatch(setDetailPopupModeAttribute('view'));
      }

      break;
    default:
      break;
  }

  return dispatch(setShowCreateGroups(true));
};

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState: initialStateAttributeSlice = {
  reloadData: false,
  showPopupContent: false,
  detailPopupMode: 'edit',
  showPopupDeleteAttributes: false,
  permissions,
  showCreateGroups: false,
};

const attributeSlice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    setReloadDataAttribute(state, action: PayloadAction<boolean>) {
      state.reloadData = action.payload;
    },
    setShowPopupContentAttribute(state, action: PayloadAction<boolean>) {
      state.showPopupContent = action.payload;
    },
    setAttributePopupContent(state, action: PayloadAction<infoSelectSPandEn | undefined>) {
      state.attributePopupContent = action.payload;
    },
    setAttributeGroupPopupContent(state, action: PayloadAction<infoGroupAttribute | undefined>) {
      state.groupPopupContent = action.payload;
    },
    setDetailPopupModeAttribute(state, action:PayloadAction<'edit' | 'create' | 'view'>) {
      state.detailPopupMode = action.payload;
    },
    setPermissionsAttribute(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
    setShowPopupDeleteAttributes(state, action: PayloadAction<boolean>) {
      state.showPopupDeleteAttributes = action.payload;
    },
    setShowCreateGroups(state, action: PayloadAction<boolean>) {
      state.showCreateGroups = action.payload;
    }
  },
});

export const { setAttributePopupContent, setDetailPopupModeAttribute, setPermissionsAttribute, setReloadDataAttribute, setShowPopupContentAttribute, setShowPopupDeleteAttributes, setShowCreateGroups, setAttributeGroupPopupContent } = attributeSlice.actions;

export default attributeSlice.reducer;
