import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { permissionOptions } from '../../../interfaces/auth';
import { Cellar, CellarSliceData } from '../../../interfaces/cellars';
import { AppDispatch } from '../..';

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

export const showPopupContentCellar = (cellar: Cellar | undefined, mode: ModalCellarMode) => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (cellar) {
        dispatch(setShippingCollarPopupContent(cellar));
        dispatch(setDetailPopupModeCellar('edit'));
      }

      break;
    case 'create':
      dispatch(setShippingCollarPopupContent(undefined));
      dispatch(setDetailPopupModeCellar('create'));
      break;
    case 'view':
      if (cellar) {
        dispatch(setShippingCollarPopupContent(cellar));
        dispatch(setDetailPopupModeCellar('view'));
      }

      break;
    case 'delete':
      if (cellar) {
        dispatch(setShippingCollarPopupContent(cellar));
        dispatch(setDetailPopupModeCellar('delete'));
      }

      break;

    default:
      break;
  }

  return dispatch(setShowPopupContentCellar(true));
};

const initialState: CellarSliceData = {
  showPopupContent: false,
  detailPopupMode: 'edit',
  permissions,
  cellarContent: undefined,
  reloadData: false
};

type ModalCellarMode = CellarSliceData['detailPopupMode']

const cellarsSlice = createSlice({
  name: 'cellarsSlice',
  initialState,
  reducers: {
    setPermissionsCellars(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
    setShowPopupContentCellar(state, action: PayloadAction<boolean>) {
      state.showPopupContent = action.payload;
    },
    setDetailPopupModeCellar(state, action:PayloadAction<ModalCellarMode>) {
      state.detailPopupMode = action.payload;
    },
    setShippingCollarPopupContent(state, action: PayloadAction<Cellar | undefined>) {
      state.cellarContent = action.payload;
    },
    setCellarsPermissions(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
    setReloadCellarData(state, action: PayloadAction<boolean>) {
      state.reloadData = action.payload;
    }
  }
});

export const {
  setDetailPopupModeCellar,
  setPermissionsCellars,
  setShippingCollarPopupContent,
  setShowPopupContentCellar,
  setCellarsPermissions,
  setReloadCellarData
} = cellarsSlice.actions;

export default cellarsSlice.reducer;

