export const sidebarConfig: sidebarConfigInterface[] = [
  {
    name: '',
    url: '',
    active: false,
  },
];

export interface sidebarConfigInterface {
    name: string,
    url: string,
    active: boolean,
    children? :
      {
        name: string,
        url: string,
        active: boolean
      }[]
  }
