import { lazy } from 'react';

import { settingsPage } from '../../interfaces/configPages';

const ReportPage = lazy(() => import('.'));

const ReportPageConfig: settingsPage = {
  routes: [
    {
      path: '/reports',
      element: <ReportPage />
    },
  ],
};

export default ReportPageConfig;
