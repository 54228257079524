import { lazy } from 'react';

import { settingsPage } from '../../interfaces/configPages';

const RolesPage = lazy(() => import('.'));

const RolesPageConfig: settingsPage = {
  routes: [
    {
      path: '/users/role',
      element: <RolesPage />
    },
  ],
};

export default RolesPageConfig;
