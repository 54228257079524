import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { initOrderDetail } from '../../../interfaces/orders';
import { dataOrdersDate, initialStateSubscriptionUserSlice, subscriptionUserComplete } from '../../../interfaces/subscriptions';

export const showPopupDeleteSubscriptionUser = (subscription: subscriptionUserComplete) => async (dispatch: AppDispatch) => {
  dispatch(setSubscriptionUserPopupContent(subscription));
  return dispatch(setShowPopupDeleteSubscriptionUser(true));
};

export const showDetailSubscriptionUser = (subscription: subscriptionUserComplete) => async (dispatch: AppDispatch) => {
  dispatch(setSubscriptionUserPopupContent(subscription));
  return dispatch(setShowDetail(true));
};

export const showEditSubscriptionUser = (subscription: subscriptionUserComplete) => async (dispatch: AppDispatch) => {
  dispatch(setSubscriptionUserPopupContent(subscription));
  return dispatch(setShowEdit(true));
};

const subscriptionUserPopupContent: subscriptionUserComplete = {
  id: '',
  due_date: '',
  status: '1',
  created_at: '',
  updated_at: '',
  users_id: '',
  shipping_frequencies_id: '',
  subscriptions_id: '',
  transactions_id: '',
  shipping_frequencies: {
    created_at: '',
    description: '',
    description_en: '',
    id: '',
    title: '',
    title_en: '',
    updated_at: '',
    value_days: ''
  },
  subscriptions: {
    created_at: '',
    days_subscriptions: 0,
    free_delivery: 0,
    free_delivery_us: 0,
    id: '',
    monts: 0,
    percen_discount: 0,
    status: 0,
    title: '',
    title_en: '',
    updated_at: '',
    start_date: ''
  },
  user_information: {
    created_at: '',
    date_birth: '',
    email: '',
    gender: '',
    id: '',
    img: '',
    last_name: '',
    name: '',
    phone: '',
    updated_at: '',
    user_validate: 1
  },
  general_order: initOrderDetail,
  transaction_amount: 0,
  keep_price: 0
};

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState: initialStateSubscriptionUserSlice = {
  reloadSubscriptionUser: false,
  showPopupDeleteSubscriptionUser: false,
  showPopupEditSubscriptionData: false,
  showPopupDisabledSubscription: false,
  subscriptionUserPopupContent,
  showDetail: false,
  showEdit: false,
  showPopupEditDates: false,
  permissions,
  showPopupEditSubscriptionUser: false,
  dataOrdersDate: [],
  shippingsNumber: 0
};

const SubscriptionUserSlice = createSlice({
  name: 'subscription/user',
  initialState,
  reducers: {
    setReloadData(state, action: PayloadAction<boolean>) {
      state.reloadSubscriptionUser = action.payload;
    },
    setShowPopupEditSubscriptionUser(state, action: PayloadAction<boolean>) {
      state.showPopupEditSubscriptionUser = action.payload;
    },
    setShowDetail(state, action: PayloadAction<boolean>) {
      state.showDetail = action.payload;
    },
    setShowEdit(state, action: PayloadAction<boolean>) {
      state.showEdit = action.payload;
    },
    setSubscriptionUserPopupContent(state, action: PayloadAction<subscriptionUserComplete>) {
      state.subscriptionUserPopupContent = action.payload;
    },
    setShowPopupDeleteSubscriptionUser(state, action: PayloadAction<boolean>) {
      state.showPopupDeleteSubscriptionUser = action.payload;
    },
    setPermissionsSubscriptionUser(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
    setShowPopupEditDates(state, action:PayloadAction<boolean>) {
      state.showPopupEditDates = action.payload;
    },
    setShowPopupEditSubscriptionData(state, action:PayloadAction<boolean>) {
      state.showPopupEditSubscriptionData = action.payload;
    },
    setDatesSubscriptions(state, action: PayloadAction<dataOrdersDate[]>) {
      state.dataOrdersDate = action.payload;
    },
    setNumberShipping(state, action: PayloadAction<number>) {
      state.shippingsNumber = action.payload;
    },
    setShowPopupDisabled(state, action: PayloadAction<boolean>) {
      state.showPopupDisabledSubscription = action.payload;
    }
  },
});

export const {
  setPermissionsSubscriptionUser,
  setReloadData,
  setShowPopupEditSubscriptionUser,
  setShowPopupDeleteSubscriptionUser,
  setSubscriptionUserPopupContent,
  setShowDetail,
  setShowPopupEditDates,
  setShowPopupEditSubscriptionData,
  setDatesSubscriptions,
  setShowEdit,
  setShowPopupDisabled,
  setNumberShipping
} = SubscriptionUserSlice.actions;

export default SubscriptionUserSlice.reducer;
