import { lazy } from 'react';

import { settingsPage } from '../../interfaces/configPages';

const CouponsPage = lazy(() => import('.'));

const CouponsPageConfig: settingsPage = {
  routes: [
    {
      path: '/coupons',
      element: <CouponsPage />
    },
  ],
};

export default CouponsPageConfig;
