import { lazy } from 'react';

import { settingsPage } from '../../../interfaces/configPages';

const AttributePage = lazy(() => import('.'));

const AttributePageConfig: settingsPage = {
  routes: [
    {
      path: '/products/atributes',
      element: <AttributePage />
    },
  ],
};

export default AttributePageConfig;
