import { AxiosResponse } from 'axios';
import { getPermissionsApi, login, recoveryPassword, recoveryPasswordResponse, resetPassword } from '../../interfaces/auth';
import api from '../axios';

export class AuthService {
  init() {}

  signIn = (data: login): Promise<AxiosResponse> =>
    new Promise((resolve, reject) => {
      api.post('pixie-users/api/users/login', data)
        .then(response => {
          this.setSession(response.headers.authorization);
          resolve(response);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );

  recoveryPassword = (data: recoveryPassword): Promise<recoveryPasswordResponse> =>
    new Promise((resolve, reject) => {
      api.post('pixie-users/api/users/recover-password', data)
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );

  resetPassword = (data: resetPassword, auth: string | null): Promise<recoveryPasswordResponse> =>
    new Promise((resolve, reject) => {
      api.post('pixie-users/api/users/change-password', data, {
        headers: {
          Authorization: auth ? auth : ''
        }
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );

  getPermissionsByRole = (idRol: string) : Promise<getPermissionsApi[]> => {
    const authorization = window.localStorage.getItem('jwt_access_token');
    return new Promise((resolve, reject) => {
      api.get(`pixie-users/api/permissions/role/${idRol}`, {
        headers: {
          Authorization: authorization ? authorization : ''
        },
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  checkSession = () : boolean => {
    if (window.localStorage.getItem('jwt_access_token'))
      return true;
    return false;
  };

  setSession = (access_token?: string) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      api.defaults.headers.common.Authorization = access_token;
    } else {
      localStorage.removeItem('jwt_access_token');
      localStorage.removeItem('id_user');
      delete api.defaults.headers.common.Authorization;
    }
  };
}

export default new AuthService();

