import { lazy } from 'react';

import { settingsPage } from '../../interfaces/configPages';

const VetPage = lazy(() => import('.'));

const VetPageConfig: settingsPage = {
  routes: [
    {
      path: '/users/vet',
      element: <VetPage />
    },
  ],
};

export default VetPageConfig;
