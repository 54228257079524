import { routesInterface, settingsPage } from '../interfaces/configPages';

class PixieUtils {
  static setRoutes(config:settingsPage) {
    let routes = [...config.routes];

    routes = routes.map(route => ({
      ...route,
    }));

    return [...routes];
  }

  static generateRoutesFromConfigs(configs:settingsPage[]): routesInterface[] {
    let allRoutes:any = [];
    configs.forEach(config => {
      allRoutes = [...allRoutes, ...this.setRoutes(config)];
    });
    return allRoutes;
  }

  static transformDate(dateString :string, format: 'DD-MM-YYYY' | 'YYYY/MM/DD' | 'YYYY-MM-DD' | 'DD/MM/YYYY'): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    switch (format) {
      case 'DD-MM-YYYY':
        return `${day}-${month}-${year}`;
      case 'YYYY/MM/DD':
        return `${year}/${month < 10 ? `0${month}` : month}/${day < 10 ? `0${day}` : day}`;
      case 'YYYY-MM-DD':
        return dateString.split('T')[0];
      case 'DD/MM/YYYY':
        return `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`;
      default:
        break;
    }

    return '';
  }
}

export default PixieUtils;
