import api from '../axios';
import { createProfile, createUserApiResponse, editProfile, selectCountry, User, UserComplete } from '../../interfaces/user';
import { permissionOptions } from '../../interfaces/auth';
import { helperSendPermissions } from '../../helper/permissionsHelper';

class ProfileService {
  init() {
  ///  this.setInterceptors();
  }

  getUsers = (permissions: permissionOptions): Promise<UserComplete[]> => {
    const authorization = window.localStorage.getItem('jwt_access_token');
    return new Promise((resolve, reject) => {
      api.get('pixie-users/api/users?limit=100', {
        headers: {
          Authorization: authorization ? authorization : '',
          expendable: helperSendPermissions(permissions, 'read')
        },
      })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  getUsersPagination = (pageNumber: number, query: string | undefined, permissions: permissionOptions): Promise<UserComplete[]> => {
    const authorization = window.localStorage.getItem('jwt_access_token');

    return new Promise((resolve, reject) => {
      api.get('pixie-users/api/users', {
        params: { filter: query, page: pageNumber },
        headers: {
          Authorization: authorization ? authorization : '',
          expendable: helperSendPermissions(permissions, 'read')
        },
      })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  getUser = (id: string): Promise<User> => {
    const authorization = window.localStorage.getItem('jwt_access_token');
    return new Promise((resolve, reject) => {
      api.get(`pixie-users/api/users/${id}`, {
        headers: {
          Authorization: authorization ? authorization : '',
        },
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  getPermissions = (rolId: string, permissions: permissionOptions): Promise<User> => {
    const authorization = window.localStorage.getItem('jwt_access_token');
    return new Promise((resolve, reject) => {
      api.get(`pixie-users/api/users/${rolId}`, {
        headers: {
          Authorization: authorization ? authorization : '',
          expendable: helperSendPermissions(permissions, 'read')
        },
      })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  createProfile = (data: createProfile, permissions: permissionOptions) : Promise<createUserApiResponse> => {
    const authorization = window.localStorage.getItem('jwt_access_token');
    return new Promise((resolve, reject) => {
      api.post('pixie-users/api/users', {
        ...data,
        rol_id: data.role.value,
        accept_termns: 1,
        headers: {
          Authorization: authorization ? authorization : '',
          expendable: helperSendPermissions(permissions, 'create')
        },
      })
        .then(response => {
          if (response.data.messaage === 'OK')
            resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  editProfile = (data: editProfile, permissions: permissionOptions) : Promise<UserComplete> => {
    // Get authorization
    const authorization = window.localStorage.getItem('jwt_access_token');
    // Delete password if not have nothing
    const copyEdit = JSON.parse(JSON.stringify(data));
    if (copyEdit.password === '')
      delete copyEdit.password;

    return new Promise((resolve, reject) => {
      api({ method: 'put', url: 'pixie-users/api/users/' + data.id,
        headers: {
          Authorization: authorization ? authorization : '',
          expendable: helperSendPermissions(permissions, 'upload')
        },
        data: { ...copyEdit,
          rol_id: data.role.value,
          accept_termns: 1,
        } })
        .then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    }
    );
  };

  deletePerson = (idUser: string, permissions: permissionOptions): Promise<any> => {
    const authorization = window.localStorage.getItem('jwt_access_token');
    return new Promise((resolve, reject) => {
      api.delete(`pixie-users/api/users/${idUser}`, {
        headers: {
          Authorization: authorization ? authorization : '',
          expendable: helperSendPermissions(permissions, 'delete')
        },
      })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  searchPerson = (query: string, permissions: permissionOptions): Promise <UserComplete[]> => {
    const authorization = window.localStorage.getItem('jwt_access_token');
    return new Promise((resolve, reject) => {
      api.get(`pixie-users/api/users?limit=100&filter=${query}`, {
        headers: {
          Authorization: authorization ? authorization : '',
          expendable: helperSendPermissions(permissions, 'read')
        },
      })
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        }
        );
    });
  };

  getCountries = ():Promise<selectCountry[]> =>
    new Promise((resolve, reject) => {
      api.get('pixie-customers/api/countries?iso=mx')
        .then(response => {
          resolve(response.data.data);
        }).catch(error => {
          reject(error.response.data.message);
        });
    });
}

const instance = new ProfileService();

export default instance;
