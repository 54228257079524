import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { permissionOptions } from '../../../interfaces/auth';

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState = {
  permissions
};

const TestimonialSlice = createSlice({
  name: 'testimonial',
  initialState,
  reducers: {
    setPermissionsTestimonials(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
  },
});

export const {
  setPermissionsTestimonials
} = TestimonialSlice.actions;

export default TestimonialSlice.reducer;
