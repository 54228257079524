import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { Appointment, initialStateAppointmentSlice } from '../../../interfaces/appointment';
import { User } from '../../../interfaces/user';

export const showPopupContentAppointment = (product: Appointment | undefined, mode: 'edit' | 'create' | 'view') => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (product) {
        dispatch(setAppointmenPopupContent(product));
        dispatch(setDetailPopupMode('edit'));
      }

      break;
    case 'create':
      dispatch(setAppointmenPopupContent(undefined));
      dispatch(setDetailPopupMode('create'));
      break;
    case 'view':
      if (product) {
        dispatch(setAppointmenPopupContent(product));
        dispatch(setDetailPopupMode('view'));
      }

      break;
    default:
      break;
  }

  return dispatch(setShowPopupContentAppointment(true));
};

export const showVetData = (vet: User | undefined, mode: 'edit' | 'create' | 'view') => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (vet) {
        dispatch(setVetPopupContent(vet));
        dispatch(setDetailPopupModeVet('edit'));
      }

      break;
    case 'create':
      dispatch(setVetPopupContent(undefined));
      dispatch(setDetailPopupModeVet('create'));
      break;
    case 'view':
      if (vet) {
        dispatch(setVetPopupContent(vet));
        dispatch(setDetailPopupModeVet('view'));
      }

      break;
    default:
      break;
  }

  return dispatch(setShowPopupContentVet(true));
};

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState: initialStateAppointmentSlice = {
  reloadAppointment: false,
  reloadVet: false,
  showPopupContentVet: false,
  showPopupContent: false,
  detailPopupMode: 'edit',
  detailPopupModeVet: 'edit',
  permissions,
  validateFormStepOne: false
};

const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  reducers: {
    setReloadDataAppointment(state, action: PayloadAction<boolean>) {
      state.reloadAppointment = action.payload;
    },
    setReloadDataVet(state, action: PayloadAction<boolean>) {
      state.reloadVet = action.payload;
    },
    setShowPopupContentAppointment(state, action: PayloadAction<boolean>) {
      state.showPopupContent = action.payload;
    },
    setShowPopupContentVet(state, action: PayloadAction<boolean>) {
      state.showPopupContentVet = action.payload;
    },
    setAppointmenPopupContent(state, action: PayloadAction<Appointment | undefined>) {
      state.appointmenPopupContent = action.payload;
    },
    setVetPopupContent(state, action: PayloadAction<User | undefined>) {
      state.vetPopupContent = action.payload as initialStateAppointmentSlice['vetPopupContent'];
    },
    setDetailPopupMode(state, action:PayloadAction<'edit' | 'create' | 'view'>) {
      state.detailPopupMode = action.payload;
    },
    setDetailPopupModeVet(state, action:PayloadAction<'edit' | 'create' | 'view'>) {
      state.detailPopupModeVet = action.payload;
    },
    setPermissionsAppointment(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
    setValidateFormStepOne(state, action: PayloadAction<boolean>) {
      state.validateFormStepOne = action.payload;
    }
  },
});

export const { setAppointmenPopupContent, setDetailPopupMode, setPermissionsAppointment, setReloadDataAppointment, setShowPopupContentAppointment, setDetailPopupModeVet, setReloadDataVet, setShowPopupContentVet, setVetPopupContent, setValidateFormStepOne } = appointmentSlice.actions;

export default appointmentSlice.reducer;
