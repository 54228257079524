import { lazy } from 'react';

import { settingsPage } from '../../interfaces/configPages';

const DashboardPage = lazy(() => import('.'));

const DashboardPageConfig: settingsPage = {
  routes: [
    {
      path: '/users/team',
      element: <DashboardPage />
    },
  ],
};

export default DashboardPageConfig;
