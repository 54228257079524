import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { initialStateUserEcommerceSlice, UserEcommerce } from '../../../interfaces/userEcommerce';

export const showPopupDeleteUserEcoimmerce = (user: UserEcommerce) => async (dispatch: AppDispatch) => {
  dispatch(setUserEcommercePopupContent(user));
  return dispatch(setShowPopupDeleteUserEcommerce(true));
};

export const showDetailUserEcommerce = (user: UserEcommerce) => async (dispatch: AppDispatch) => {
  dispatch(setUserEcommercePopupContent(user));
  return dispatch(setShowDetail(true));
};

export const showEditUserEcommerce = (user: UserEcommerce) => async (dispatch: AppDispatch) => {
  dispatch(setUserEcommercePopupContent(user));
  return dispatch(setShowEdit(true));
};

const UserEcommercePopupContent: UserEcommerce = {
  code_number: '',
  created_at: '',
  date_birth: '',
  email: '',
  gender: '',
  id: '',
  img: '',
  last_name: '',
  mp_customers_id: '',
  name: '',
  phone: '',
  social_login: 0,
  stripe_customers_id: '',
  updated_at: '',
  user_validate: 0
};

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

const initialState: initialStateUserEcommerceSlice = {
  reloadUserEcommerce: false,
  showPopupEditUserEcommerce: false,
  showPopupDeleteUserEcommerce: false,
  UserEcommercePopupContent,
  showDetail: false,
  showEdit: false,
  permissions,
};

const UserEcommerceSlice = createSlice({
  name: 'ecommerce/user',
  initialState,
  reducers: {
    setReloadData(state, action: PayloadAction<boolean>) {
      state.reloadUserEcommerce = action.payload;
    },
    setShowPopupEditUserEcommerce(state, action: PayloadAction<boolean>) {
      state.showPopupEditUserEcommerce = action.payload;
    },
    setShowDetail(state, action: PayloadAction<boolean>) {
      state.showDetail = action.payload;
    },
    setShowEdit(state, action: PayloadAction<boolean>) {
      state.showEdit = action.payload;
    },
    setUserEcommercePopupContent(state, action: PayloadAction<UserEcommerce>) {
      state.UserEcommercePopupContent = action.payload;
    },
    setShowPopupDeleteUserEcommerce(state, action: PayloadAction<boolean>) {
      state.showPopupDeleteUserEcommerce = action.payload;
    },
    setPermissionsUserEcommerce(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
  },
});

export const { setPermissionsUserEcommerce, setReloadData, setShowPopupEditUserEcommerce, setShowPopupDeleteUserEcommerce, setUserEcommercePopupContent, setShowDetail, setShowEdit } = UserEcommerceSlice.actions;

export default UserEcommerceSlice.reducer;
