import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../..';
import { permissionOptions } from '../../../interfaces/auth';
import { Gift, GiftForm, GiftSliceData } from '../../../interfaces/gift';

const permissions : permissionOptions = {
  create: 0,
  delete: 0,
  read: 0,
  upload: 0
};

type ModalMode = GiftSliceData['popupMode'];

export const showPopupContentGift = (gift: Gift | undefined, mode: ModalMode) => async (dispatch: AppDispatch) => {
  switch (mode) {
    case 'edit':
      if (gift) {
        dispatch(setGiftPopupContent(gift));
        dispatch(setDetailPopupModeGift('edit'));
      }

      break;
    case 'create':
      dispatch(setGiftPopupContent(undefined));
      dispatch(setDetailPopupModeGift('create'));
      break;
    case 'view':
      if (gift) {
        dispatch(setGiftPopupContent(gift));
        dispatch(setDetailPopupModeGift('view'));
      }

      break;
    default:
      break;
  }

  return dispatch(setShowPopupContentGift(true));
};

const initialState: GiftSliceData = {
  reloadData: false,
  showPopup: false,
  popupMode: 'create',
  gift: undefined,
  permissions,
  showPopupSeeOrders: false
};

const citiesSlice = createSlice({
  name: 'gift',
  initialState,
  reducers: {
    setReloadDataGift(state, action: PayloadAction<boolean>) {
      state.reloadData = action.payload;
    },
    setShowPopupContentGift(state, action: PayloadAction<boolean>) {
      state.showPopup = action.payload;
    },
    setGiftPopupContent(state, action: PayloadAction<Gift | undefined>) {
      state.gift = action.payload;
    },
    setDetailPopupModeGift(state, action:PayloadAction<ModalMode>) {
      state.popupMode = action.payload;
    },
    setPermissionsGift(state, action: PayloadAction<permissionOptions>) {
      state.permissions = action.payload;
    },
    setFormGift(state, action: PayloadAction<GiftForm>) {
      state.formCreate = action.payload;
    },
    setshowPopupSeeOrders(state, action: PayloadAction<boolean>) {
      state.showPopupSeeOrders = action.payload;
    }
  },
});

export const {
  setDetailPopupModeGift,
  setGiftPopupContent,
  setPermissionsGift,
  setReloadDataGift,
  setShowPopupContentGift,
  setFormGift,
  setshowPopupSeeOrders
} = citiesSlice.actions;

export default citiesSlice.reducer;
