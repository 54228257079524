import { lazy } from 'react';

import { settingsPage } from '../../../interfaces/configPages';

const GiftPage = lazy(() => import('.'));

const GiftPageConfig: settingsPage = {
  routes: [
    {
      path: '/products/gift',
      element: <GiftPage />
    },
  ],
};

export default GiftPageConfig;
