import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppDispatch } from '../../store/';

import { login } from '../../interfaces/auth';
import authService from '../../services/authService';

import { setLoadPermissions, setUserData } from './userSlice';
import { setHiddenNavigation } from '../../store/pixie/navigationSlice';

export const submitLogin = (data: login) => async (dispatch: AppDispatch) =>
  authService
    .signIn(data)
    .then(({ data, headers }) => {
      dispatch(setUserData(data.regUser, headers.rli));
      // Show navigation when the user is connect
      dispatch(setHiddenNavigation(false));
      // Load permission
      return dispatch(setLoadPermissions(headers.rli));
      /// return dispatch(loginSuccess());
    })
    .catch(errors => dispatch(loginError(errors)));

const initialState = {
  success: false,
  errors: false,
  isLoadLoad: false,
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    loginSuccess(state) {
      state.success = true;
      state.errors = false;
    },
    loginExit(state) {
      state.success = false;
    },
    loginError(state, action: PayloadAction<boolean>) {
      state.success = false;
      state.errors = action.payload;
    },
    resetError(state, action: PayloadAction<boolean>) {
      state.success = action.payload;
      state.errors = false;
    },
    setDataLoad(state) {
      state.isLoadLoad = true;
    },
  },
  extraReducers: {},
});

export const { loginSuccess, loginError, loginExit, setDataLoad, resetError } = loginSlice.actions;

export default loginSlice.reducer;
