import { lazy } from 'react';

import { settingsPage } from '../../../interfaces/configPages';

const CitiesPage = lazy(() => import('.'));

const CitiesPageConfig: settingsPage = {
  routes: [
    {
      path: '/logistics/cities',
      element: <CitiesPage />
    },
  ],
};

export default CitiesPageConfig;
